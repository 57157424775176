import classNames from "classnames";
import "./Checkbox.scss";

interface CheckboxProps {
  value: boolean;
  onChange?: (value: boolean) => void;
}

const Checkbox = (props: CheckboxProps) => {
  return (
    <label className="checkbox">
      <input
        type="checkbox"
        checked={props.value}
        onChange={(e) => props.onChange && props.onChange(e.target.checked)}
      />
      <span
        className={classNames("checkmark", {
          "checkmark--checked": props.value,
        })}></span>
    </label>
  );
};

export default Checkbox;
