import { useMemo } from "react";
import { useAppSelector } from "../../app/hooks";
import { useConfig } from "../../config";
import { usePREReencryptionBaseFragmentsSubscription } from "../../search/pre";
import { selectContactsByPubKey } from "../../views/Contacts/contactSlice";

interface SharedWithProps {
  dataId: string;
  reencryptionRequests: string[];
}

export const SharedWith = ({
  dataId,
  reencryptionRequests,
}: SharedWithProps) => {
  const config = useConfig();
  const contacts = useAppSelector(selectContactsByPubKey);

  const fragments = usePREReencryptionBaseFragmentsSubscription(
    config.preContract,
    dataId,
  );

  if (fragments.error !== undefined) {
    console.log("SharedWith: failed to get fragment: ", fragments.error);
  }

  const fragmentShare = useMemo(() => {
    if (fragments.data === undefined) return [];
    const arr: string[] = [];
    fragments.data.forEach((v) => {
      arr.push(v.delegatee_pubkey);
    });
    return arr;
  }, [fragments.data]);

  const shared = useMemo(() => {
    return Array.from(new Set([...fragmentShare, ...reencryptionRequests]));
  }, [fragmentShare, reencryptionRequests]);

  return (
    <div className="my-files-shared-with">
      {shared.map((s, index) => (
        <div key={index} className="my-files-shared-with-name">
          <span>{contacts[s] === undefined ? s : contacts[s].name}</span>
        </div>
      ))}
    </div>
  );
};
