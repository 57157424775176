import { useCallback, useEffect, useMemo, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import {
  ExecuteCoins,
  ExecuteMsgs,
  usePREContract,
} from "../../app/cosmosServices";
import { useAppSelector } from "../../app/hooks";
import shareFile from "../../assets/share-file.svg";
import { ShareFile, ShareResponse, TriggerShare } from "../UploadFile/Share";
import search from "../../assets/search.svg";
import wallet from "../../assets/walletWhite.svg";

import "./ShareWithContact.scss";
import { selectAccount } from "../Keplr/walletSlice";
import { toast } from "react-toastify";
import { useConfig } from "../../config";

import { useDataSubscription } from "../../search/pre";
import { PREDataUserFriendly } from "../../search/preTypes";
import { snooze, stringFromBase64 } from "../../utils";
import { useFilterData, useSearchedData } from "../Data/utils";
import { useCosmWasmClient } from "../../app/cosmosServices/ContractProvider";
import { add } from "../../config/utils";
import { multiSimulate } from "../../app/cosmosServices/utils";
import { selectContacts } from "../../views/Contacts/contactSlice";
import { Button } from "../Button";

declare let window: Window;

interface ShareDataProps {
  contact: string;
  pubKey: string;
}

export const ShareWithContact = ({ contact, pubKey }: ShareDataProps) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <Button
        onClick={() => setShow(true)}
        icon={shareFile}
        title="Share File"
      />
      <ShareDataOffcanvas
        show={show}
        setShow={setShow}
        pubKeyB64={pubKey}
        contact={contact}></ShareDataOffcanvas>
    </>
  );
};

interface ShareDataOffcanvasProps {
  show: boolean;
  setShow: (show: boolean) => void;
  pubKeyB64: string;
  contact: string;
}

const ShareDataOffcanvas = ({
  show,
  setShow,
  pubKeyB64,
  contact,
}: ShareDataOffcanvasProps) => {
  const [filesToShare, setFilesToShare] = useState<string[]>([]);
  const contract = usePREContract();
  const [submitting, setSubmitting] = useState(false);
  const [dataSearch, setDataSearch] = useState<string>("");
  const [shares, setShares] = useState(new Map<string, TriggerShare>());
  const [costs, setCosts] = useState(new Map<string, number>());
  const account = useAppSelector(selectAccount);
  const config = useConfig();
  const cosmWasmClient = useCosmWasmClient();
  const [txFee, setTxFee] = useState(0);
  const contacts = useAppSelector(selectContacts);

  const { data, error } = useDataSubscription(config.preContract, pubKeyB64);

  if (error !== undefined) {
    console.log("[ShareWithContact]: failed to get data");
  }

  useEffect(() => {
    if (filesToShare.length === 0) {
      setShares(new Map<string, TriggerShare>());
      setCosts(new Map<string, number>());
      return;
    }
    setShares((p) => {
      const m = new Map<string, TriggerShare>();
      filesToShare.forEach((dataId) => {
        m.set(
          dataId,
          p.get(dataId) ?? {
            share: async (
              dataId: string,
            ): Promise<ShareResponse | undefined> => {
              return;
            },
          },
        );
      });
      return m;
    });
    setCosts((p) => {
      const m = new Map<string, number>();
      filesToShare.forEach((name) => {
        m.set(name, p.get(name) ?? 0);
      });
      return m;
    });
  }, [filesToShare]);

  const handleSubmit = async (e: any) => {
    e.stopPropagation();
    if (window.fetchBrowserWallet?.umbral === undefined) return;
    if (contract === undefined) return;
    if (submitting === true) return;
    try {
      setSubmitting(true);
      const msgs: ExecuteMsgs = [];
      const coins: ExecuteCoins = [];
      const sharesArray = Array.from(shares);
      for (const [key, value] of sharesArray) {
        const s = await value.share(key);
        if (s === undefined) {
          console.log(`Failed to share file ${key} with ${contact}!`);
          continue;
        }
        if (s.delegationMsg !== undefined) {
          msgs.push(s.delegationMsg);
          coins.push(undefined);
        }
        msgs.push(s.reencryptionMsg);
        coins.push(s.reencryptionCoins);
      }
      const resultPromise = contract.multiExecute(account.address, msgs, coins);
      toast.promise(resultPromise, {
        pending: "Sharing file...",
        success: "File shared!",
        error: "Failed to share file!",
      });
      const result = await resultPromise;
      console.log(`[ShareData]: share tx sent: ${result}`);
    } catch (error) {
      console.log("AddDataForm: failed to submit data: ", error);
    } finally {
      setSubmitting(false);
      setFilesToShare([]);
      setDataSearch("");
      handleClose();
    }
  };

  useEffect(() => {
    if (cosmWasmClient === undefined) return;
    if (account === undefined) return;
    (async function () {
      if (cosmWasmClient === undefined) return;
      const sharesArray = Array.from(shares);
      const preDataMsgs: ExecuteMsgs = [];
      const postDataMsgs: ExecuteMsgs = [];
      const preDataCoins: ExecuteCoins = [];
      const postDataCoins: ExecuteCoins = [];
      for (const [key, value] of sharesArray) {
        let s = await value.share(key);
        while (s === undefined) {
          await snooze(100);
          s = await value.share(key);
        }
        if (s.delegationMsg !== undefined) {
          preDataMsgs.push(s.delegationMsg);
          preDataCoins.push(undefined);
        }
        postDataMsgs.push(s.reencryptionMsg);
        postDataCoins.push(s.reencryptionCoins);
      }
      const msgs = [...preDataMsgs, ...postDataMsgs];
      const coins = [...preDataCoins, ...postDataCoins];
      if (msgs.length > 0) {
        try {
          const fees = await multiSimulate(
            cosmWasmClient,
            config,
            account.address,
            config.preContract,
            msgs,
            coins,
          );
          for (const fee of fees.amount) {
            if (fee.denom === config.coinDenom) {
              setTxFee(config.toUICoin(Number.parseFloat(fee.amount)));
              break;
            }
          }
        } catch (Error) {
          // no action
        }
      } else {
        setTxFee(0);
      }
    })();
  }, [shares, cosmWasmClient, account, contract, config.preContract, config]);

  const removeShare = useCallback(
    (user: string) =>
      setFilesToShare((p) => p.filter((filterUser) => filterUser !== user)),
    [],
  );

  const updateCost = useCallback((user: string, cost: number) => {
    setCosts((p) => {
      p.set(user, cost);
      return new Map(p);
    });
  }, []);

  const handleClose = () => setShow(false);

  const filteredData = useFilterData(
    data,
    config.expireTimeMS,
    config.expireTimestamp,
    undefined,
    undefined,
  );

  const titleMap = useMemo(() => {
    const m = new Map<string, string>();
    if (filteredData === undefined) return m;
    filteredData.forEach((v) => {
      m.set(v.data_id, v.tags.get("title") ?? "");
    });
    return m;
  }, [filteredData]);

  const searchedData = useSearchedData(filteredData, dataSearch);

  const reencryptionCost = useMemo(() => {
    return config.toUICoin(
      Array.from(costs)
        .map((v) => v[1])
        .reduce((p, c) => p + c, 0),
    );
  }, [costs, config]);

  const totalCost = useMemo(() => {
    return add(reencryptionCost, txFee);
  }, [reencryptionCost, txFee]);

  const reader = useMemo(() => {
    if (contact.length === 0) return "";
    return contacts[contact].pubKey;
  }, [contact, contacts]);

  // prettier-ignore
  return (
    <Offcanvas
      show={show}
      onHide={handleClose}
      className="file-upload"
      size="lg"
      placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Share file</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <p>Share file with this contact</p>
        <div id="fileInfo">
          <div>{contact}</div>
          <div>{reader.length > 0 ? reader.slice(0, 20) : reader}...</div>
        </div>
        {/* todo: duplicated from UploadFile. Make component. */}
        <div className="file-upload-heading">File to share</div>
        <div className="file-upload-search">
          <div>
            <img src={search} />
            <input
              placeholder="Search file..."
              value={dataSearch}
              onChange={(e) => setDataSearch(e.target.value)}
            />
          </div>
          {dataSearch.length > 0 &&
            searchedData !== undefined &&
            searchedData.length > 0 && (
            <div className="file-upload-search-list">
              {searchedData.map((d: PREDataUserFriendly) => {
                return (
                  <div
                    key={d.data_id}
                    className="file-upload-search-list-contact"
                    onClick={() => {
                      for (const did of filesToShare) {
                        if (did === d.data_id) return;
                      }
                      setFilesToShare([...filesToShare, d.data_id]);
                      setDataSearch("");
                    }}>
                    {stringFromBase64(d.tags.get("title"))}
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div className="file-upload-share-with">
          {!filesToShare.length && (
            <div className="file-upload-share-with-empty">
              Please select files
            </div>
          )}
          <div className="file-upload-warning">
            Note: once a file has been shared and the contact has gained access
            this cannot be undone.
          </div>
          {!!filesToShare.length &&
            filesToShare.map((dataId, index) => (
              <ShareFile
                key={index}
                contactName={contact}
                pubKey={pubKeyB64}
                component={shares.get(dataId)}
                remove={removeShare}
                updateCost={updateCost}
                id={dataId}
                title={titleMap.get(dataId) ?? dataId}
              />
            ))}
        </div>
        <div className="file-upload-cost-title">
          <div className="file-upload-heading">Cost estimation</div>
          <div className="file-upload-cost-token">
            <img src={wallet} />
            {config.uiCoinDenom.toUpperCase()}
          </div>
        </div>
        <div className="file-upload-cost-detail">
          <div>Transaction fee</div>
          <div>
            {txFee}
          </div>
        </div>
        <div className="file-upload-cost-detail">
          <div>Sharing fee</div>
          <div>
            {reencryptionCost}
          </div>
        </div>
        <div className="file-upload-cost">
          <div>Total cost</div>
          <div>
            {totalCost}
          </div>
        </div>
        <div className="file-upload-container">
          <Button onClick={(e) => handleSubmit(e)} disabled={submitting} title="Share File" ></Button>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};
