import { useAppSelector } from "../../app/hooks";
import { selectError } from "./errorSlice";

export const Error = () => {
  const error = useAppSelector(selectError);
  return (
    <div>
      <h1 style={{ color: "red", fontSize: 36, fontStyle: "bold" }}>
        {error.title}
      </h1>
      <p style={{ color: "red", fontSize: 26, fontStyle: "bold" }}>
        {error.message}
      </p>
    </div>
  );
};
