import { useMemo } from "react";
import { PREDataUserFriendly } from "../../search/preTypes";
import { stringFromBase64 } from "../../utils";
import Fuse from "fuse.js";

export interface FilteredDataType extends PREDataUserFriendly {
  expire: number;
  timestamp: Date;
  selectionId: string;
  select: boolean;
  changeSelect: (key: string, value: boolean) => void;
}

export const useFilterData = (
  data: PREDataUserFriendly[] | undefined,
  expireTimeMS: number,
  expireTimestamp: string,
  selection: Map<string, boolean> | undefined,
  setSelection:
    | ((_: (_: Map<string, boolean>) => Map<string, boolean>) => void)
    | undefined,
): FilteredDataType[] | undefined => {
  return useMemo(() => {
    if (data === undefined) return undefined;
    const changeSelection = (key: string, value: boolean) => {
      if (setSelection === undefined) return;
      setSelection((prev) => {
        prev.set(key, value);
        return new Map(prev);
      });
    };
    const now = Date.now();
    let f = data.map((d) => {
      const n = {
        ...d,
        expire: 0,
        timestamp: new Date(d.block.timestamp),
        selectionId: d.data_id,
        select: selection?.get(d.data_id) ?? false,
        changeSelect: changeSelection,
      };
      n.tags.set("title", stringFromBase64(n.tags.get("title") ?? ""));
      return n;
    });
    if (expireTimeMS > 0) {
      f = f.map((d) => ({
        ...d,
        expire: expireTimeMS - (now - d.timestamp.getTime()),
      }));
    } else if (expireTimestamp.length > 0) {
      const parts = expireTimestamp.split(":");
      if (parts.length === 3) {
        const date = new Date();
        date.setUTCHours(Number.parseInt(parts[0], 10));
        date.setUTCMinutes(Number.parseInt(parts[1], 10));
        date.setUTCSeconds(Number.parseInt(parts[2], 10));
        const clean = new Date(date);
        clean.setUTCDate(clean.getUTCDate() - 1);
        f = f.map((d) => ({
          ...d,
          expire:
            d.timestamp.getTime() - clean.getTime() > 0
              ? date.getTime() - Date.now()
              : -1,
        }));
      }
    }

    return f.filter((d) => d.expire > 0);
  }, [data, expireTimeMS, expireTimestamp, selection, setSelection]);
};

export const useSearchedData = (
  filteredData: FilteredDataType[] | undefined,
  searchBarText: string,
): FilteredDataType[] | undefined => {
  return useMemo(() => {
    if (searchBarText === "") return filteredData;
    if (filteredData === undefined) return filteredData;
    const options = {
      includeScore: true,
      keys: [
        {
          name: "title",
          getFn: (row: PREDataUserFriendly) => row.tags.get("title") ?? "",
        },
        {
          name: "type",
          getFn: (row: PREDataUserFriendly) => row.tags.get("type") ?? "",
        },
      ],
    };

    const fuse = new Fuse(filteredData, options);

    const result = fuse.search(searchBarText);
    return result
      .filter((v) => v.score !== undefined && v.score < 0.1)
      .map((v) => v.item);
  }, [filteredData, searchBarText]);
};
