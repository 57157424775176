import { NavLink } from "react-router-dom";
import classNames from "classnames";

import group from "../../assets/group.svg";
// import dashboard from "../../assets/dashboard.svg";
import files from "../../assets/files.svg";
import filesOrange from "../../assets/files-orange.svg";
import share from "../../assets/share.svg";
import shareOrange from "../../assets/share-orange.svg";
import address from "../../assets/address.svg";
import addressOrange from "../../assets/address-orange.svg";
import info from "../../assets/info.svg";
// import trash from "../../assets/trash.svg";
import appIcon from "../../assets/app.svg";

import "./NavBar.scss";

interface NavBarProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const NavBar = (props: NavBarProps) => {
  const { open, setOpen } = props;

  return (
    <div className="nav-bar" style={{ width: open ? "230px" : "75px" }}>
      <div className="nav-bar-container">
        <div
          className={classNames("nav-bar-container-heading", {
            "nav-bar-container-heading--close": !open,
          })}>
          {open && (
            <div className="nav-bar-container-heading-title">
              <img src={appIcon} alt="DabbaFlow" id="appHeaderIcon" />
              <div id="appHeaderTitle">
                <div id="appHeaderTitleMain">DabbaFlow</div>
                <div id="appHeaderTitleSub">Powered by Fetch.ai</div>
              </div>
            </div>
          )}
          <div
            className={classNames({ "nav-bar-container-heading-icon": open })}>
            <img
              src={group}
              alt="Hide navigation"
              onClick={() => setOpen(!open)}></img>
          </div>
        </div>
        <div
          className={classNames("nav-bar-container-navigation", {
            "nav-bar-container-navigation-close": !open,
          })}>
          {/* <NavLink
            to="/"
            className={({ isActive }) =>
              classNames({
                "nav-bar-container-navigation--active": !!isActive,
              })
            }>
            <img src={dashboard} alt="Dashboard" />
            {open && <span>Dashboard</span>}
          </NavLink>
          */}
          <NavLink
            to="/files"
            className={({ isActive }) =>
              classNames({
                "nav-bar-container-navigation--active": !!isActive,
              })
            }>
            {(props: any) => (
              <>
                <img
                  src={props.isActive ? filesOrange : files}
                  alt="My Files"
                />
                {open && <span>My Files</span>}
              </>
            )}
          </NavLink>
          <NavLink
            to="/shared-files"
            className={({ isActive }) =>
              classNames({
                "nav-bar-container-navigation--active": !!isActive,
              })
            }>
            {(props) => (
              <>
                <img
                  src={props.isActive ? shareOrange : share}
                  alt="Shared Files"
                />
                {open && <span>Shared Files</span>}
              </>
            )}
          </NavLink>
          <NavLink
            to="/contacts"
            className={({ isActive }) =>
              classNames({
                "nav-bar-container-navigation--active": !!isActive,
              })
            }>
            {(props) => (
              <>
                <img
                  src={props.isActive ? addressOrange : address}
                  alt="Contacts"
                />
                {open && <span>Contacts</span>}
              </>
            )}
          </NavLink>
          {/* <NavLink
            to="/deleted-files"
            className={({ isActive }) =>
              classNames({
                "nav-bar-container-navigation--active": !!isActive,
              })
            }>
            <img src={trash} alt="Deleted Files" />
            {open && <span>Deleted Files</span>}
          </NavLink>
          */}
          <div className="nav-bar-container-navigation-bottom">
            <a href="https://fetchai.github.io/open-colearn/webapp_tutorial/">
              <img src={info} alt="Info" />
              {open && <span>How to use</span>}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
