import { fromBase64, toBase64 } from "@cosmjs/encoding";

export function snooze(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const base64regex =
  /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;

export function isBase64(text: string): boolean {
  return base64regex.test(text);
}

/**
 * Format bytes as human-readable text.
 *
 * @param {number} bytes Number of bytes.
 * @param {boolean} si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param {number} dp Number of decimal places to display.
 *
 * @return {string} Formatted string.
 */
export function humanFileSize(
  bytes: number,
  si: boolean = false,
  dp: number = 1,
) {
  const thresh = si ? 1000 : 1024;

  if (Number.isSafeInteger(bytes) === false) {
    return "";
  }

  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + " " + units[u];
}

export const randomInteger = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min)) + min;
};

export const stringToBase64 = (v: string | undefined): string => {
  if (v === undefined || v.length === 0) return "";
  const encoder = new TextEncoder();
  return toBase64(encoder.encode(v));
};

export const stringFromBase64 = (v: string | undefined): string => {
  if (v === undefined || v.length === 0) return "";
  if (isBase64(v) === false) return v;
  const decoder = new TextDecoder();
  const bytes = fromBase64(v);
  return decoder.decode(bytes);
};
