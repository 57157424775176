import { Router } from "./Router";
import ReactGA from "react-ga4";

import "./App.scss";

const TRACKING_ID = "G-KZ0VJE5DPL";
ReactGA.initialize(TRACKING_ID);

function App() {
  return <Router />;
}

export default App;
