import { SigningCosmWasmClient } from "@cosmjs/cosmwasm-stargate";
import { Coin } from "@cosmjs/launchpad";
import { Tag } from "./types";
import { ExecuteCoins, ExecuteMsg, ExecuteMsgs, multiExecute } from "./utils";
export interface PREContractInterface {
  addDelegation(
    sender: string,
    delegatorPubKey: string,
    delegateePubKey: string,
    proxyDelegations: Map<string, string>,
    memo: string,
  ): Promise<string>;

  addData(
    sender: string,
    dataId: string,
    delegatorPubkey: string,
    capsule: string,
    tags: Tag[],
  ): Promise<string>;

  addDataAndShare(
    sender: string,
    dataId: string,
    delegatorPubkey: string,
    capsule: string,
    tags: Tag[],
    preDataMsgs: ExecuteMsgs,
    preDataCoins: ExecuteCoins,
    postDataMsgs: ExecuteMsgs,
    postDataCoins: ExecuteCoins,
  ): Promise<string>;

  requestReencryption(
    sender: string,
    dataId: string,
    delegateePubKey: string,
    coinAmount: number,
    coinDenom: string,
    memo: string,
  ): Promise<string>;

  removeData(sender: string, dataId: string): Promise<string>;

  multiExecute(
    sender: string,
    msgs: ExecuteMsgs,
    coins: ExecuteCoins,
  ): Promise<string>;

  prepareDelegation(
    delegatorPubKey: string,
    delegateePubKey: string,
    proxyDelegations: Map<string, string>,
  ): ExecuteMsg;

  prepareReencryption(
    dataId: string,
    delegateePubKey: string,
    coinAmount: number,
    coinDenom: string,
  ): [ExecuteMsg, Coin[]];

  prepareAddData(
    data_id: string,
    delegator_pubkey: string,
    capsule: string,
    tags: Tag[],
  ): ExecuteMsg;
}

/* eslint camelcase: ["error", {allow: [
    add_delegation,
    delegator_pubkey,
    delegatee_pubkey,
    proxy_delegations,
    proxy_pubkey,
    delegation_string,
    add_data,
    data_id,
    request_reencryption,
    remove_data,
]}] */
export const createPREContract = (
  contractAddress: string,
  client: SigningCosmWasmClient,
): PREContractInterface => {
  const prepareDelegation = (
    delegator_pubkey: string,
    delegatee_pubkey: string,
    proxyDelegations: Map<string, string>,
  ): ExecuteMsg => {
    return {
      add_delegation: {
        delegator_pubkey,
        delegatee_pubkey,
        proxy_delegations: Array.from(
          proxyDelegations,
          ([pubKey, delegationString]) => ({
            proxy_pubkey: pubKey,
            delegation_string: delegationString,
          }),
        ),
      },
    };
  };

  const addDelegation = async (
    sender: string,
    delegator_pubkey: string,
    delegatee_pubkey: string,
    proxyDelegations: Map<string, string>,
    memo: string,
  ): Promise<string> => {
    const msg = prepareDelegation(
      delegator_pubkey,
      delegatee_pubkey,
      proxyDelegations,
    );
    console.log("Add Delegator MSG: ", msg);
    const result = await client.execute(
      sender,
      contractAddress,
      msg,
      "auto",
      memo,
    );
    return result.transactionHash;
  };

  const prepareAddData = (
    data_id: string,
    delegator_pubkey: string,
    capsule: string,
    tags: Tag[],
  ) => {
    return {
      add_data: {
        data_id,
        delegator_pubkey,
        capsule,
        tags,
        title: "",
        description: "",
      },
    };
  };

  const addData = async (
    sender: string,
    data_id: string,
    delegator_pubkey: string,
    capsule: string,
    tags: Tag[],
  ): Promise<string> => {
    const msg = prepareAddData(data_id, delegator_pubkey, capsule, tags);
    console.log("Add Data MSG: ", msg);
    const result = await client.execute(
      sender,
      contractAddress,
      msg,
      "auto",
      `add data: ${data_id}`,
    );
    return result.transactionHash;
  };

  const addDataAndShare = async (
    sender: string,
    dataId: string,
    delegatorPubkey: string,
    capsule: string,
    tags: Tag[],
    preDataMsgs: ExecuteMsgs,
    preDataCoins: ExecuteCoins,
    postDataMsgs: ExecuteMsgs,
    postDataCoins: ExecuteCoins,
  ): Promise<string> => {
    const msg = prepareAddData(dataId, delegatorPubkey, capsule, tags);
    const msgs = [...preDataMsgs, msg, ...postDataMsgs];
    const coins = [...preDataCoins, undefined, ...postDataCoins];
    const result = await multiExecute(
      client,
      sender,
      contractAddress,
      msgs,
      "auto",
      "add data and share",
      coins,
    );
    return result.transactionHash;
  };

  const prepareReencryption = (
    data_id: string,
    delegatee_pubkey: string,
    coinAmount: number,
    coinDenom: string,
  ): [ExecuteMsg, Coin[]] => {
    const msg = {
      request_reencryption: {
        data_id,
        delegatee_pubkey,
      },
    };
    const coins: Coin[] = [
      {
        denom: coinDenom,
        amount: coinAmount.toString(),
      },
    ];
    return [msg, coins];
  };

  const requestReencryption = async (
    sender: string,
    data_id: string,
    delegatee_pubkey: string,
    coinAmount: number,
    coinDenom: string,
    memo: string,
  ): Promise<string> => {
    const [msg, coins] = prepareReencryption(
      data_id,
      delegatee_pubkey,
      coinAmount,
      coinDenom,
    );
    const result = await client.execute(
      sender,
      contractAddress,
      msg,
      "auto",
      memo,
      coins,
    );
    return result.transactionHash;
  };

  const removeData = async (
    sender: string,
    dataId: string,
  ): Promise<string> => {
    const msg = {
      remove_data: {
        data_id: dataId,
      },
    };
    const result = await client.execute(
      sender,
      contractAddress,
      msg,
      "auto",
      `remove data: ${dataId}`,
    );
    return result.transactionHash;
  };

  const multiExecuteImpl = async (
    sender: string,
    msgs: ExecuteMsgs,
    coins: ExecuteCoins,
  ): Promise<string> => {
    const result = await multiExecute(
      client,
      sender,
      contractAddress,
      msgs,
      "auto",
      "",
      coins,
    );
    return result.transactionHash;
  };

  return {
    prepareAddData,
    prepareDelegation,
    addDelegation,
    addData,
    addDataAndShare,
    prepareReencryption,
    requestReencryption,
    removeData,
    multiExecute: multiExecuteImpl,
  };
};
