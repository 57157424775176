import {
  ExecuteResult,
  MsgExecuteContractEncodeObject,
  SigningCosmWasmClient,
} from "@cosmjs/cosmwasm-stargate";
import { fromBase64, toBase64, toUtf8 } from "@cosmjs/encoding";
import { PREProxy } from "../../search/preTypes";
import { randomInteger } from "../../utils";
import { PREContractInterface } from "./PREContract";
import { Tag } from "./types";
import {
  calculateFee,
  Coin,
  DeliverTxResponse,
  GasPrice,
  isDeliverTxFailure,
  logs,
  StdFee,
} from "@cosmjs/stargate";
import { MsgExecuteContract } from "cosmjs-types/cosmwasm/wasm/v1/tx";
import { AppConfig } from "../../config";

export type ExecuteMsg = Record<string, unknown>;
export type ExecuteMsgs = ExecuteMsg[];
export type ExecuteCoin = readonly Coin[] | undefined;
export type ExecuteCoins = ExecuteCoin[];

export const throwNotInitialized = (): any => {
  throw new Error("Not yet initialized");
};

export const tag = (key: string, value: string): Tag => {
  return {
    key,
    value,
  };
};

export const createDelegation = async (
  chainId: string,
  contract: PREContractInterface,
  pubkeyB64: string,
  reader: string,
  threshold: number,
  proxies: PREProxy[],
): Promise<[ExecuteMsg, number] | undefined> => {
  if (window.fetchBrowserWallet?.umbral === undefined) return;
  let selectedProxies = proxies.slice();
  if (proxies.length > threshold) {
    const sorted = selectedProxies.sort(
      (a, b) => Math.random() - a.stake / (a.stake + b.stake),
    );
    const limit =
      threshold + ((proxies.length - threshold) * randomInteger(5, 40)) / 100;
    selectedProxies = sorted.slice(0, limit);
  }
  const readerPubKey = fromBase64(reader);
  const fragments =
    await window.fetchBrowserWallet?.umbral.generateKeyFragments(
      chainId,
      readerPubKey,
      threshold,
      selectedProxies.length,
    );
  const delegationStrings = new Map<string, string>();
  for (let i = 0; i < selectedProxies.length; ++i) {
    const pk = fromBase64(selectedProxies[i].pubkey);
    const result = await window.fetchBrowserWallet.umbral.encrypt(
      pk,
      fragments[i].data,
    );
    if (
      result === undefined ||
      result.capsule === undefined ||
      result.cipherText === undefined
    ) {
      console.log(
        "[ShareData][createDelegation]: encrypted delegation undefined",
      );
      return;
    }
    const delegation = new Uint8Array(
      result.capsule.length + result.cipherText.length,
    );
    delegation.set(result.capsule);
    delegation.set(result.cipherText, result.capsule.length);
    delegationStrings.set(selectedProxies[i].pubkey, toBase64(delegation));
  }

  const msg = await contract.prepareDelegation(
    pubkeyB64,
    reader,
    delegationStrings,
  );

  return [msg, selectedProxies.length];
};

function createDeliverTxResponseErrorMessage(
  result: DeliverTxResponse,
): string {
  return `Error when broadcasting tx ${result.transactionHash} at height ${result.height}.
    Code: ${result.code}; Raw log: ${result.rawLog}`;
}

export const multiExecute = async (
  client: SigningCosmWasmClient,
  senderAddress: string,
  contractAddress: string,
  msgs: ExecuteMsgs,
  fee: StdFee | "auto" | number,
  memo = "",
  funds?: ExecuteCoins,
): Promise<ExecuteResult> => {
  const executeContractMsgs: MsgExecuteContractEncodeObject[] = [];

  for (let i = 0; i < msgs.length; ++i) {
    executeContractMsgs.push({
      typeUrl: "/cosmwasm.wasm.v1.MsgExecuteContract",
      value: MsgExecuteContract.fromPartial({
        sender: senderAddress,
        contract: contractAddress,
        msg: toUtf8(JSON.stringify(msgs[i])),
        funds: [...(funds?.at(i) || [])],
      }),
    });
  }
  const result = await client.signAndBroadcast(
    senderAddress,
    executeContractMsgs,
    fee,
    memo,
  );
  if (isDeliverTxFailure(result)) {
    throw new Error(createDeliverTxResponseErrorMessage(result));
  }
  return {
    logs: logs.parseRawLog(result.rawLog),
    transactionHash: result.transactionHash,
  };
};

export const multiSimulate = async (
  client: SigningCosmWasmClient,
  config: AppConfig,
  senderAddress: string,
  contractAddress: string,
  msgs: ExecuteMsgs,
  funds?: ExecuteCoins,
): Promise<StdFee> => {
  const executeContractMsgs: MsgExecuteContractEncodeObject[] = [];

  for (let i = 0; i < msgs.length; ++i) {
    executeContractMsgs.push({
      typeUrl: "/cosmwasm.wasm.v1.MsgExecuteContract",
      value: MsgExecuteContract.fromPartial({
        sender: senderAddress,
        contract: contractAddress,
        msg: toUtf8(JSON.stringify(msgs[i])),
        funds: [...(funds?.at(i) || [])],
      }),
    });
  }

  const gasEstimation = await client.simulate(
    senderAddress,
    executeContractMsgs,
    "",
  );

  const gasPrice = GasPrice.fromString(`${config.gasPrice}${config.feeToken}`);

  return calculateFee(Math.round(gasEstimation * 1.3), gasPrice);
};
