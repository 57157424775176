import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Button } from "../../components/Button";
import { selectAccounts } from "../../components/Keplr";
import { setError } from "../Error";

export const Home = () => {
  const accounts = useAppSelector(selectAccounts);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/files");
  }, [navigate]);

  const handleError = () => {
    dispatch(setError("error happened. this is a test message"));
    navigate("/error");
  };

  return (
    <div>
      <h3>Home View</h3>
      <h4>Welcome: {accounts[0]?.address}</h4>
      <p> This is the home view</p>
      <br />
      <br />
      <Button onClick={() => handleError()} title="Simulate error" />
    </div>
  );
};
