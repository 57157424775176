import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  split,
  HttpLink,
  from,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { getMainDefinition } from "@apollo/client/utilities";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { useEffect, useMemo } from "react";
// eslint-disable-next-line
import jwt_decode from "jwt-decode";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import {
  newSearchAuthToken,
  selectSearchSlice,
  setInProgress,
} from "./searchSlice";
import { useConfig } from "../config";
import { getJWT } from "./auth";
import { JWTType } from "./types";
import { toast } from "react-toastify";

export const SearchProvider = (props: any) => {
  const searchState = useAppSelector(selectSearchSlice);
  const dispatch = useAppDispatch();
  const config = useConfig();

  useEffect(() => {
    if (searchState.inProgress === true) {
      return;
    }
    if (searchState == undefined || searchState.account === undefined) {
      return;
    }
    if (
      searchState.authToken !== undefined &&
      searchState.authToken.length > 0
    ) {
      const jwt = jwt_decode(searchState.authToken) as JWTType;
      const expired = Math.floor(Date.now() / 1000) >= jwt.exp;
      const addressMatch = searchState.account.address === jwt.name;
      const pubKeyMatch = searchState.account.pubkey === jwt.pubkey;
      if (expired === false && addressMatch === true && pubKeyMatch === true) {
        return;
      }
    }
    // load jwt token from api as current token was rejected
    console.log("[SearchProvider][useEffect::1]: launch async method");
    (async function () {
      console.log("Get JWT token for search from API");
      if (searchState.account === undefined) return;
      dispatch(setInProgress());
      try {
        const tokenPromise = getJWT(
          config.chainId,
          searchState.account,
          config.authServiceURI,
        );
        toast.promise(tokenPromise, {
          pending: "Authenticating with wallet...",
          success: "Wallet connected!",
          error: "Failed to authenticate with wallet!",
        });
        tokenPromise.catch((err) => {
          console.log("Error in getting jwt token: ", err);
        });
        const token = await tokenPromise;
        if (token === undefined) {
          return;
        }
        dispatch(
          newSearchAuthToken({
            token,
          }),
        );
      } catch (error: any) {
        // no block
      }
    })().catch((e) => {
      console.log("[SearchProvider][useEffect::1]: error in async method ", e);
    });
  }, [
    searchState,
    searchState.authToken,
    searchState.account,
    config.authServiceURI,
    config.chainId,
    dispatch,
  ]);

  const errorLink = useMemo(
    () =>
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          graphQLErrors.forEach(({ message, locations, path }) =>
            console.log(
              `[SearchProvider][GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            ),
          );
        }

        if (networkError) {
          console.log(`[SearchProvider][Network error]: ${networkError}`);
        }
      }),
    [],
  );

  const client = useMemo(() => {
    if (
      config.graphqlHttpURI.length === 0 ||
      config.graphqlWsURI.length === 0
    ) {
      return new ApolloClient({
        cache: new InMemoryCache(),
      });
    }
    const httpLink = new HttpLink({
      uri: config.graphqlHttpURI,
    });
    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          authorization: searchState.authToken
            ? `Bearer ${searchState.authToken}`
            : "",
        },
      };
    });
    const wsLink = new GraphQLWsLink(
      createClient({
        url: config.graphqlWsURI,
        connectionParams: {
          headers: {
            Authorization: searchState.authToken
              ? `Bearer ${searchState.authToken}`
              : "",
          },
        },
      }),
    );
    const splitLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === "OperationDefinition" &&
          definition.operation === "subscription"
        );
      },
      wsLink,
      authLink.concat(httpLink),
    );
    return new ApolloClient({
      link: from([errorLink, splitLink]),
      cache: new InMemoryCache(),
    });
  }, [
    searchState.authToken,
    config.graphqlHttpURI,
    config.graphqlWsURI,
    errorLink,
  ]);

  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
};
