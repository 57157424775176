import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface ErrorState {
  title: string;
  message: string;
}

const initialState: ErrorState = {
  title: "Error",
  message: "Unknown error happened.",
};

export const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<string>) => {
      state.message = action.payload;
    },
  },
});

export const { setError } = errorSlice.actions;
export default errorSlice.reducer;

export const selectError = (state: RootState) => state.error;
