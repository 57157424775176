import React, { ReactChild, ReactChildren, useEffect, useState } from "react";
import { parseConfigFromEnv } from "./parser";
import { AppConfig, CoinMapValue } from "./types";

const defaultConfig: AppConfig = {
  initialized: false,
  chainName: "",
  chainId: "",
  rpcAddress: "",
  restAddress: "",
  addressPrefix: "",
  feeToken: "",
  gasPrice: 0,
  walletInstallLink: "",
  coinMap: new Map<string, CoinMapValue>(),
  walletConfig: null,
  authServiceURI: "",
  graphqlHttpURI: "",
  graphqlWsURI: "",
  ipfsURI: "",
  apiBackoff: {
    maxMS: 30000,
    incrementsMS: 500,
    randomMS: 500,
  },
  coinDenom: "",
  uiCoinDenom: "",
  coin: { denom: "", decimals: 0, coinGeckoId: "" },
  preContract: "",
  expireTimeMS: 0,
  expireTimestamp: "",
  blockExplorer: "",
  landingURL: "",
  faucetURL: "",

  toUICoin: (_: number) => 0,
};

const ConfigContext = React.createContext<AppConfig>(defaultConfig);

interface ConfigProviderProps {
  children: ReactChild | ReactChildren | ReactChild[] | ReactChildren[];
}

export const ConfigProvider = ({ children }: ConfigProviderProps) => {
  const [value, setValue] = useState<AppConfig>(defaultConfig);

  useEffect(() => {
    if (value.initialized === true) return;

    setValue({
      ...parseConfigFromEnv(value),
      initialized: true,
    });
  }, [value]);

  return (
    <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>
  );
};

export const useConfig = () => React.useContext(ConfigContext);
