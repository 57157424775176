import { configureStore } from "@reduxjs/toolkit";
import errorReducer from "../views/Error/errorSlice";
import walletReducer from "../components/Keplr/walletSlice";
import contactsReducer from "../views/Contacts/contactSlice";
import searchReducer from "../search/searchSlice";
import searchBarReducer from "../components/AppHeader/searchBarSlice";

export const store = configureStore({
  reducer: {
    error: errorReducer,
    wallet: walletReducer,
    contacts: contactsReducer,
    search: searchReducer,
    searchBar: searchBarReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

store.subscribe(() => {
  localStorage.setItem(
    "contacts",
    JSON.stringify(store.getState().contacts.contacts),
  );
  localStorage.setItem(
    "contacts_key_map",
    JSON.stringify(store.getState().contacts.keyMap),
  );
});
