import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { AccountType } from "../components/Keplr/walletSlice";

interface SearchSliceType {
  authToken: string;
  inProgress: boolean;
  account: AccountType | undefined;
}

const initialState: SearchSliceType = {
  authToken: localStorage.getItem("search_auth_token") ?? "",
  inProgress: false,
  account: undefined,
};

export interface NewAuthTokenType {
  token: string;
}

const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    newSearchAuthToken: (state, action: PayloadAction<NewAuthTokenType>) => {
      state.authToken = action.payload.token;
      localStorage.setItem("search_auth_token", action.payload.token);
      state.inProgress = false;
    },
    setInProgress: (state) => {
      state.inProgress = true;
    },
    clearToken: (state) => {
      localStorage.setItem("search_auth_token", "");
      state.authToken = "";
      state.account = undefined;
      state.inProgress = false;
    },
    triggerSearchConnect: (state, action: PayloadAction<AccountType>) => {
      state.account = action.payload;
    },
  },
});

export default searchSlice.reducer;

export const {
  newSearchAuthToken,
  setInProgress,
  clearToken,
  triggerSearchConnect,
} = searchSlice.actions;

export const selectSearchSlice = (state: RootState) => state.search;
