import { Button } from "../Button";

import "./PageHeader.scss";

interface PageHeaderProps {
  onAction?: () => void;
  actionIcon?: string;
  actionText?: string;
  heading: string;
  details: string;
}

const PageHeader = (props: PageHeaderProps) => {
  return (
    <div className="page-header">
      <div className="page-header-info">
        <div className="page-header-info-heading">{props.heading}</div>
        <div className="page-header-info-details">{props.details}</div>
      </div>
      {props.onAction && (
        <div className="page-header-action">
          <Button
            onClick={() => props.onAction && props.onAction()}
            title={props.actionText ?? ""}
            icon={props.actionIcon}
          />
        </div>
      )}
    </div>
  );
};

export default PageHeader;
