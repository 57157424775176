import React, {
  ReactChild,
  ReactChildren,
  useCallback,
  useEffect,
  useState,
} from "react";
import { OfflineSigner } from "@cosmjs/proto-signing";
import { GasPrice } from "@cosmjs/stargate";
import { SigningCosmWasmClient } from "@cosmjs/cosmwasm-stargate";

import { useConfig } from "../../config";
import { toast } from "react-toastify";

import { createPREContract, PREContractInterface } from "./PREContract";

interface ContractContextType {
  readonly initialized: boolean;
  readonly connected: boolean;
  readonly init: (signer: OfflineSigner) => void;
  readonly getPREContract: () => PREContractInterface | undefined;
  readonly getClient: () => SigningCosmWasmClient | undefined;
}

const defaultContractContext: ContractContextType = {
  initialized: false,
  init: async () => undefined,
  getPREContract: () => undefined,
  getClient: () => undefined,
  connected: false,
};

const ContractContext = React.createContext<ContractContextType>(
  defaultContractContext,
);

export const useContract = (): ContractContextType =>
  React.useContext(ContractContext);

export const usePREContract = (): PREContractInterface | undefined => {
  const context = React.useContext(ContractContext);
  return context.getPREContract();
};

export const useCosmWasmClient = (): SigningCosmWasmClient | undefined => {
  const context = React.useContext(ContractContext);
  return context.getClient();
};

interface ContractProviderProps {
  children: ReactChild | ReactChildren | ReactChild[] | ReactChildren[];
}

export const ContractProvider = ({ children }: ContractProviderProps) => {
  const config = useConfig();
  // const [stargateClient, setStargateClient] = useState<SigningStargateClient>();
  const [cosmWasmClient, setCosmWasmClient] = useState<SigningCosmWasmClient>();

  const createClients = useCallback(
    async (signer: OfflineSigner): Promise<void> => {
      try {
        /* const sc = await SigningStargateClient.connectWithSigner(
          config.rpcAddress,
          signer,
      );
      setStargateClient(sc); */
        const c = await SigningCosmWasmClient.connectWithSigner(
          config.rpcAddress,
          signer,
          {
            prefix: config.addressPrefix,
            gasPrice: GasPrice.fromString(
              `${config.gasPrice}${config.feeToken}`,
            ),
          },
        );
        setCosmWasmClient(c);
      } catch (error) {
        console.log("Failed to get clients: ", error);
        toast.error("Failed to get client connection to blockchain!");
        throw error;
      }
    },
    [config.addressPrefix, config.rpcAddress, config.feeToken, config.gasPrice],
  );

  const contextWithInit = { ...defaultContractContext, init: createClients };
  const [value, setValue] = useState<ContractContextType>(contextWithInit);

  const [preContract, setPreContract] = useState<PREContractInterface>();

  useEffect(() => {
    setValue({ ...defaultContractContext, init: createClients });
  }, [createClients]);

  useEffect(() => {
    if (!cosmWasmClient) return;

    if (preContract !== undefined) return;

    const contract = createPREContract(config.preContract, cosmWasmClient);
    setPreContract(contract);
  }, [cosmWasmClient, config.preContract, preContract]);

  useEffect(() => {
    if (!preContract || !cosmWasmClient) return;

    setValue({
      initialized: true,
      init: async () => {},
      getPREContract: () => preContract,
      getClient: () => cosmWasmClient,
      connected: cosmWasmClient !== undefined,
    });
  }, [preContract, cosmWasmClient]);

  return (
    <ContractContext.Provider value={value}>
      {children}
    </ContractContext.Provider>
  );
};
