import {
  gql,
  LazyQueryResult,
  SubscriptionResult,
  useLazyQuery,
  useQuery,
  useSubscription,
} from "@apollo/client";
import { Tag } from "../app/cosmosServices/types";
import {
  ContractVar,
  PREActiveProxiesResponse,
  PREContractResponse,
  PREDataIdVars,
  PREDataResponse,
  PREDataUserFriendly,
  PREDelegationResponse,
  PREDelegationVars,
  PREFragment,
  PREFragmentBase,
  PREFragmentUserFriendly,
  PREProxyVars,
  PREPubKeyVars,
  PREReencryptionFragmentsResponse,
  PREReencryptionRequestDetailedResponse,
  PREReencryptionRequestResponse,
  PREReencryptionRequestVars,
  ReencryptionInfo,
} from "./preTypes";
import {} from "./types";

/* eslint camelcase: ["error", {allow: [
  delegator_address,
  data_id,
  pre_delegation,
  pre_get_reencryption_requests,
  pre_reencrypted_fragment,
]}] */

const SUBSCRIBE_PROXY = gql`
  subscription getProxies($contract: String, $state: proxy_state) {
    pre_proxy(
      where: { _and: { contract: { _eq: $contract }, state: { _eq: $state } } }
      order_by: { stake: desc }
    ) {
      address
      pubkey
      stake
      state
    }
  }
`;

const QUERY_PROXY = gql`
  query getProxies($contract: String, $state: proxy_state) {
    pre_proxy(
      where: { _and: { contract: { _eq: $contract }, state: { _eq: $state } } }
      order_by: { stake: desc }
    ) {
      address
      pubkey
      stake
      state
    }
  }
`;

const SUBSCRIBE_DELEGATION = gql`
  subscription getDelegation($contract: String, $pubkey: String) {
    pre_delegation(
      where: {
        _and: {
          contract: { _eq: $contract }
          delegator_pubkey: { _eq: $pubkey }
        }
      }
    ) {
      delegator_pubkey
      delegatee_pubkey
      proxy_delegations
    }
  }
`;

const SUBSCRIBE_DATA = gql`
  subscription getData($contract: String, $pubkey: String) {
    pre_data(
      where: {
        _and: {
          contract: { _eq: $contract }
          delegator_pubkey: { _eq: $pubkey }
        }
      }
    ) {
      delegator_pubkey
      data_id
      tags
      delegator_address
      height
      block {
        timestamp
      }
    }
  }
`;

const QUERY_DATA = gql`
  query getData($contract: String, $data_id: String) {
    pre_data(
      where: {
        _and: { contract: { _eq: $contract }, data_id: { _eq: $data_id } }
      }
    ) {
      delegator_pubkey
      data_id
      tags
      delegator_address
      height
      capsule
      block {
        timestamp
      }
    }
  }
`;

const QUERY_DELEGATION_PROXIES = gql`
  query getDelegation(
    $contract: String
    $delegator: String
    $delegatee: String
  ) {
    pre_delegation(
      where: {
        _and: {
          contract: { _eq: $contract }
          delegator_pubkey: { _eq: $delegator }
          delegatee_pubkey: { _eq: $delegatee }
        }
      }
    ) {
      proxy_delegations
      delegatee_pubkey
    }
  }
`;

const SUBSCRIBE_DELEGATION_PROXIES = gql`
  subscription getDelegation(
    $contract: String
    $delegator: String
    $delegatee: String
  ) {
    pre_delegation(
      where: {
        _and: {
          contract: { _eq: $contract }
          delegator_pubkey: { _eq: $delegator }
          delegatee_pubkey: { _eq: $delegatee }
        }
      }
    ) {
      proxy_delegations
      delegatee_pubkey
    }
  }
`;

const QUERY_CONTRACT = gql`
  query getContract($contract: String) {
    pre(where: { contract: { _eq: $contract } }) {
      contract
      threshold
      stake_denom
      per_proxy_request_reward_amount
    }
  }
`;

const SUBSCRIBE_REENCRYPTION_REQUESTS = gql`
  subscription getRequests($contract: String, $delegator_address: String) {
    pre_get_reencryption_requests(
      args: {
        contract_address: $contract
        delegator_address: $delegator_address
      }
    ) {
      delegatee_pubkey
      data_id
    }
  }
`;

const QUERY_REENCRYPTION_REQUESTS_BY_DATA_ID = gql`
  query getRequests($contract: String, $data_id: String) {
    pre_reencryption_requests(
      where: {
        _and: { contract: { _eq: $contract }, data_id: { _eq: $data_id } }
      }
    ) {
      delegatee_pubkey
      data_id
      height
      block {
        timestamp
      }
    }
  }
`;

const SUBSCRIBE_REENCRYPTION_REQUESTS_BY_DATA_ID = gql`
  subscription getRequests($contract: String, $data_id: String) {
    pre_reencryption_requests(
      where: {
        _and: { contract: { _eq: $contract }, data_id: { _eq: $data_id } }
      }
    ) {
      delegatee_pubkey
      data_id
      height
      block {
        timestamp
      }
    }
  }
`;

const SUBSCRIBE_REENCRYPTION_FRAGMENTS_BASE = gql`
  subscription getFragments($contract: String, $data_id: String) {
    pre_reencrypted_fragment(
      where: {
        _and: { contract: { _eq: $contract }, data_id: { _eq: $data_id } }
      }
    ) {
      data_id
      delegatee_pubkey
    }
  }
`;

const QUERY_REENCRYPTION_FRAGMENTS = gql`
  query getFragments($contract: String, $data_id: String) {
    pre_reencrypted_fragment(
      where: {
        _and: { contract: { _eq: $contract }, data_id: { _eq: $data_id } }
      }
      order_by: { height: desc }
    ) {
      data_id
      delegatee_pubkey
      fragment
      proxy_addr
      height
      block {
        timestamp
      }
      data {
        delegator_pubkey
        tags
      }
    }
  }
`;

const SUBSCRIBE_REENCRYPTION_FRAGMENTS_BY_DATA_ID = gql`
  subscription getFragments($contract: String, $data_id: String) {
    pre_reencrypted_fragment(
      where: {
        _and: { contract: { _eq: $contract }, data_id: { _eq: $data_id } }
      }
    ) {
      data_id
      delegatee_pubkey
      fragment
      proxy_addr
      height
      block {
        timestamp
      }
      data {
        delegator_pubkey
        tags
      }
    }
  }
`;

const SUBSCRIBE_REENCRYPTION_FRAGMENTS = gql`
  subscription getFragments($contract: String, $pubkey: String) {
    pre_reencrypted_fragment(
      where: {
        _and: {
          contract: { _eq: $contract }
          delegatee_pubkey: { _eq: $pubkey }
        }
      }
    ) {
      data_id
      delegatee_pubkey
      fragment
      proxy_addr
      height
      block {
        timestamp
      }
      data {
        delegator_pubkey
        tags
      }
    }
  }
`;

export const useActiveProxiesSubscription = (contract: string) => {
  return useSubscription<PREActiveProxiesResponse, PREProxyVars>(
    SUBSCRIBE_PROXY,
    {
      variables: {
        contract,
        state: "registered",
      },
    },
  );
};

export const useDelegationsSubscription = (
  contract: string,
  pubkey: string,
) => {
  return useSubscription<PREDelegationResponse, PREPubKeyVars>(
    SUBSCRIBE_DELEGATION,
    {
      variables: {
        contract,
        pubkey,
      },
    },
  );
};

export const useActiveProxiesLazyQuery = (): [
  (contract: string) => void,
  LazyQueryResult<PREActiveProxiesResponse, PREProxyVars>,
] => {
  const [fire, response] = useLazyQuery<PREActiveProxiesResponse, PREProxyVars>(
    QUERY_PROXY,
  );
  const getter = (contract: string) => {
    fire({
      variables: {
        contract,
        state: "registered",
      },
    });
  };
  return [getter, response];
};

const tagsToMap = (tags: Tag[]): Map<string, string> => {
  const r = new Map<string, string>();
  for (const kv of tags) {
    r.set(kv.key, kv.value);
  }
  return r;
};

export const makeFriendlyPREData = (
  input: PREDataResponse | undefined,
): PREDataUserFriendly[] | undefined => {
  if (input === undefined) return undefined;
  return input.pre_data.map((i) => ({
    ...i,
    tags: tagsToMap(i.tags),
  }));
};

export const makeFriendlyPREFragment = (
  input: PREFragment,
): PREFragmentUserFriendly => {
  /* eslint-disable */
  return {
    ...input,
    data:
      input.data !== null && input.data !== undefined
        ? {
            ...input.data,
            tags: tagsToMap(input.data.tags),
          }
        : undefined,
  };
  /* eslint-enable */
};

export const useDataSubscription = (
  contract: string,
  pubkey: string,
): SubscriptionResult<PREDataUserFriendly[], PREPubKeyVars> => {
  const r = useSubscription<PREDataResponse, PREPubKeyVars>(SUBSCRIBE_DATA, {
    variables: {
      contract,
      pubkey,
    },
  });
  return {
    ...r,
    data: makeFriendlyPREData(r.data),
  };
};

export const useDelegatorProxiesQuery = (): [
  (contract: string, delegator: string, delegatee: string) => void,
  LazyQueryResult<PREDelegationResponse, PREDelegationVars>,
  (contract: string, delegator: string, delegatee: string) => void,
] => {
  const [fire, response] = useLazyQuery<
    PREDelegationResponse,
    PREDelegationVars
  >(QUERY_DELEGATION_PROXIES);
  const getter = (contract: string, delegator: string, delegatee: string) => {
    fire({
      variables: {
        contract,
        delegator,
        delegatee,
      },
    });
  };
  return [
    getter,
    response,
    (contract: string, delegator: string, delegatee: string) => {
      response.subscribeToMore({
        document: SUBSCRIBE_DELEGATION_PROXIES,
        variables: {
          contract,
          delegator,
          delegatee,
        },
        updateQuery: (prev, { subscriptionData }) => {
          console.log("updateQuery: ", prev, subscriptionData);
          if (!subscriptionData.data) return prev;

          return Object.assign({}, prev, {
            pre_delegation: [
              ...subscriptionData.data.pre_delegation,
              ...prev.pre_delegation,
            ],
          });
        },
      });
    },
  ];
};

export const useDelegatorProxiesSubscription = (
  contract: string,
  delegator: string,
  delegatee: string,
) => {
  return useSubscription<PREDelegationResponse, PREDelegationVars>(
    SUBSCRIBE_DELEGATION_PROXIES,
    {
      variables: {
        contract,
        delegator,
        delegatee,
      },
    },
  );
};

export const usePREContractQuery = (contract: string) => {
  const r = useQuery<PREContractResponse, ContractVar>(QUERY_CONTRACT, {
    variables: {
      contract,
    },
  });
  return {
    ...r,
    data: r.data?.pre.at(0),
  };
};

export const usePREReencryptionRequestSubscription = (
  contract: string,
  delegator_address: string,
): SubscriptionResult<ReencryptionInfo[], PREReencryptionRequestVars> => {
  const r = useSubscription<
    PREReencryptionRequestResponse,
    PREReencryptionRequestVars
  >(SUBSCRIBE_REENCRYPTION_REQUESTS, {
    variables: {
      contract,
      delegator_address,
    },
  });
  return {
    ...r,
    data: r.data?.pre_get_reencryption_requests,
  };
};

export const usePREReencryptionRequestByDataLazySubscribe = (
  contract: string,
  data_id: string,
): [
  (contract: string, data_id: string) => void,
  LazyQueryResult<PREReencryptionRequestDetailedResponse, PREDataIdVars>,
] => {
  const [fire, response] = useLazyQuery<
    PREReencryptionRequestDetailedResponse,
    PREDataIdVars
  >(QUERY_REENCRYPTION_REQUESTS_BY_DATA_ID, {
    variables: {
      contract,
      data_id,
    },
  });
  const getter = (contract: string, data_id: string) => {
    const options = {
      variables: {
        contract,
        data_id,
      },
    };
    fire(options);
    response.subscribeToMore({
      ...options,
      document: SUBSCRIBE_REENCRYPTION_REQUESTS_BY_DATA_ID,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        if (
          !prev.pre_reencryption_requests ||
          prev.pre_reencryption_requests.length === 0
        )
          return subscriptionData.data;

        return Object.assign({}, prev, {
          pre_get_reencryption_requests: [
            ...prev.pre_reencryption_requests,
            ...subscriptionData.data.pre_reencryption_requests,
          ],
        });
      },
    });
  };
  return [getter, response];
};

export const usePREReencryptionFragmentsLazyQuery = (
  contract: string,
  data_id: string,
): [
  (contract: string, data_id: string) => void,
  LazyQueryResult<PREReencryptionFragmentsResponse<PREFragment>, PREDataIdVars>,
] => {
  const [fire, response] = useLazyQuery<
    PREReencryptionFragmentsResponse<PREFragment>,
    PREDataIdVars
  >(QUERY_REENCRYPTION_FRAGMENTS, {
    variables: {
      contract,
      data_id,
    },
  });
  const getter = (contract: string, data_id: string) => {
    const options = {
      variables: {
        contract,
        data_id,
      },
    };
    fire(options);
    response.subscribeToMore({
      ...options,
      document: SUBSCRIBE_REENCRYPTION_FRAGMENTS_BY_DATA_ID,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        if (
          !prev.pre_reencrypted_fragment ||
          prev.pre_reencrypted_fragment.length === 0
        )
          return subscriptionData.data;

        return Object.assign({}, prev, {
          pre_reencrypted_fragment: [
            ...prev.pre_reencrypted_fragment,
            ...subscriptionData.data.pre_reencrypted_fragment,
          ],
        });
      },
    });
  };
  return [getter, response];
};

export const usePREReencryptionBaseFragmentsSubscription = (
  contract: string,
  data_id: string,
): SubscriptionResult<PREFragmentBase[], PREDataIdVars> => {
  const r = useSubscription<
    PREReencryptionFragmentsResponse<PREFragmentBase>,
    PREDataIdVars
  >(SUBSCRIBE_REENCRYPTION_FRAGMENTS_BASE, {
    variables: {
      contract,
      data_id,
    },
  });
  return {
    ...r,
    data: r.data?.pre_reencrypted_fragment,
  };
};

export const usePREReencryptionFragmentsSubscription = (
  contract: string,
  pubkey: string,
): SubscriptionResult<PREFragment[], PREPubKeyVars> => {
  const r = useSubscription<
    PREReencryptionFragmentsResponse<PREFragment>,
    PREPubKeyVars
  >(SUBSCRIBE_REENCRYPTION_FRAGMENTS, {
    variables: {
      contract,
      pubkey,
    },
  });
  return {
    ...r,
    data: r.data?.pre_reencrypted_fragment,
  };
};

export const useDataLazyQuery = (contract: string, data_id: string) => {
  return useLazyQuery<PREDataResponse, PREDataIdVars>(QUERY_DATA, {
    variables: {
      contract,
      data_id,
    },
  });
};
