import { useCallback, useEffect, useMemo, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import {
  ExecuteCoins,
  ExecuteMsgs,
  usePREContract,
} from "../../app/cosmosServices";
import { useAppSelector } from "../../app/hooks";
import { PREDataUserFriendly } from "../../search/preTypes";
import { humanFileSize, snooze, stringFromBase64 } from "../../utils";
import shareFile from "../../assets/share-file.svg";
import { selectContacts } from "../../views/Contacts/contactSlice";
import { ShareFile, ShareResponse, TriggerShare } from "../UploadFile/Share";
import search from "../../assets/search.svg";
import wallet from "../../assets/walletWhite.svg";

import "./ShareData.scss";
import { selectAccount } from "../Keplr/walletSlice";
import { toast } from "react-toastify";
import { useConfig } from "../../config";

import Fuse from "fuse.js";
import { add } from "../../config/utils";
import { useCosmWasmClient } from "../../app/cosmosServices/ContractProvider";
import { multiSimulate } from "../../app/cosmosServices/utils";
import { Button } from "../Button";

declare let window: Window;

interface ShareDataProps {
  data: PREDataUserFriendly;
  pubKey: string;
}

export const ShareData = ({ data, pubKey }: ShareDataProps) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <Button
        onClick={() => setShow(true)}
        title="Share File"
        icon={shareFile}></Button>
      <ShareDataOffcanvas
        show={show}
        setShow={setShow}
        pubKeyB64={pubKey}
        data={data}></ShareDataOffcanvas>
    </>
  );
};

interface ShareDataOffcanvasProps {
  show: boolean;
  setShow: (show: boolean) => void;
  pubKeyB64: string;
  data: PREDataUserFriendly;
}

export const ShareDataOffcanvas = ({
  show,
  setShow,
  pubKeyB64,
  data,
}: ShareDataOffcanvasProps) => {
  const [shareWith, setShareWith] = useState<string[]>([]);
  const contract = usePREContract();
  const [submitting, setSubmitting] = useState(false);
  const [contactSearch, setContactSearch] = useState<string>("");
  const contacts = useAppSelector(selectContacts);
  const [shares, setShares] = useState(new Map<string, TriggerShare>());
  const [costs, setCosts] = useState(new Map<string, number>());
  const account = useAppSelector(selectAccount);
  const config = useConfig();
  const cosmWasmClient = useCosmWasmClient();
  const [txFee, setTxFee] = useState(0);

  useEffect(() => {
    if (shareWith.length === 0) {
      setShares(new Map<string, TriggerShare>());
      setCosts(new Map<string, number>());
      return;
    }
    setShares((p) => {
      const m = new Map<string, TriggerShare>();
      shareWith.forEach((name) => {
        m.set(
          name,
          p.get(name) ?? {
            share: async (
              dataId: string,
            ): Promise<ShareResponse | undefined> => {
              return;
            },
          },
        );
      });
      return m;
    });
    setCosts((p) => {
      const m = new Map<string, number>();
      shareWith.forEach((name) => {
        m.set(name, p.get(name) ?? 0);
      });
      return m;
    });
  }, [shareWith]);

  const handleSubmit = async (e: any) => {
    e.stopPropagation();
    if (window.fetchBrowserWallet?.umbral === undefined) return;
    if (contract === undefined) return;
    if (submitting === true) return;
    try {
      setSubmitting(true);
      const msgs: ExecuteMsgs = [];
      const coins: ExecuteCoins = [];
      const sharesArray = Array.from(shares);
      for (const [key, value] of sharesArray) {
        const s = await value.share(data.data_id);
        if (s === undefined) {
          console.log(`Failed to share file ${data.data_id} with ${key}!`);
          continue;
        }
        if (s.delegationMsg !== undefined) {
          msgs.push(s.delegationMsg);
          coins.push(undefined);
        }
        msgs.push(s.reencryptionMsg);
        coins.push(s.reencryptionCoins);
      }
      const resultPromise = contract.multiExecute(account.address, msgs, coins);
      toast.promise(resultPromise, {
        pending: "Sharing file...",
        success: "File shared!",
        error: "Failed to share file!",
      });
      const result = await resultPromise;
      console.log(`[ShareData]: share tx sent: ${result}`);
    } catch (error) {
      console.log("AddDataForm: failed to submit data: ", error);
    } finally {
      setSubmitting(false);
      setShareWith([]);
      setContactSearch("");
      handleClose();
    }
  };

  useEffect(() => {
    if (cosmWasmClient === undefined) return;
    if (account === undefined) return;
    (async function () {
      if (cosmWasmClient === undefined) return;
      const sharesArray = Array.from(shares);
      const preDataMsgs: ExecuteMsgs = [];
      const postDataMsgs: ExecuteMsgs = [];
      const preDataCoins: ExecuteCoins = [];
      const postDataCoins: ExecuteCoins = [];
      for (const [, value] of sharesArray) {
        let s = await value.share(data.data_id);
        while (s === undefined) {
          await snooze(100);
          s = await value.share(data.data_id);
        }
        if (s.delegationMsg !== undefined) {
          preDataMsgs.push(s.delegationMsg);
          preDataCoins.push(undefined);
        }
        postDataMsgs.push(s.reencryptionMsg);
        postDataCoins.push(s.reencryptionCoins);
      }
      const msgs = [...preDataMsgs, ...postDataMsgs];
      const coins = [...preDataCoins, ...postDataCoins];
      if (msgs.length > 0) {
        try {
          const fees = await multiSimulate(
            cosmWasmClient,
            config,
            account.address,
            config.preContract,
            msgs,
            coins,
          );
          for (const fee of fees.amount) {
            if (fee.denom === config.coinDenom) {
              setTxFee(config.toUICoin(Number.parseFloat(fee.amount)));
              break;
            }
          }
        } catch (Error) {
          // no action
        }
      } else {
        setTxFee(0);
      }
    })();
  }, [
    shares,
    cosmWasmClient,
    account,
    contract,
    config.preContract,
    config,
    data.data_id,
  ]);

  const removeShare = useCallback(
    (user: string) =>
      setShareWith((p) => p.filter((filterUser) => filterUser !== user)),
    [],
  );

  const updateCost = useCallback((user: string, cost: number) => {
    setCosts((p) => {
      p.set(user, cost);
      return new Map(p);
    });
  }, []);

  const handleClose = () => setShow(false);

  const filteredContacts = useMemo(() => {
    if (contactSearch === "") return [];
    const options = {
      includeScore: true,
      keys: ["name", "notes", "pubKey"],
    };

    const c = Object.values(contacts);
    const fuse = new Fuse(c, options);

    const result = fuse.search(contactSearch);
    return result
      .filter((v) => v.score !== undefined && v.score < 0.1)
      .map((v) => v.item);
  }, [contacts, contactSearch]);

  const reencryptionCost = useMemo(() => {
    return config.toUICoin(
      Array.from(costs)
        .map((v) => v[1])
        .reduce((p, c) => p + c, 0),
    );
  }, [costs, config]);

  const totalCost = useMemo(() => {
    return add(reencryptionCost, txFee);
  }, [reencryptionCost, txFee]);

  return (
    <Offcanvas
      show={show}
      onHide={handleClose}
      className="file-upload"
      size="lg"
      placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Share this file</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <p>Share this file with your contact(s)</p>
        <div id="fileInfo">
          <div>{stringFromBase64(data.tags.get("title"))}</div>
          <div>
            {humanFileSize(Number.parseInt(data.tags.get("size") ?? ""))}
          </div>
          <div>{new Date(data.block.timestamp).toLocaleString()}</div>
        </div>
        {/* todo: duplicated from UploadFile. Make component. */}
        <div className="file-upload-heading">Share file with</div>
        <div className="file-upload-search">
          <div>
            <img src={search} />
            <input
              placeholder="Search contacts..."
              value={contactSearch}
              onChange={(e) => setContactSearch(e.target.value)}
            />
          </div>
          {filteredContacts.length > 0 && (
            <div className="file-upload-search-list">
              {filteredContacts
                .filter((contact: any) => !shareWith.includes(contact.name))
                .map((contact: any) => {
                  return (
                    <div
                      key={contact.name}
                      className="file-upload-search-list-contact"
                      onClick={() =>
                        setShareWith([...shareWith, contact.name])
                      }>
                      {contact.name}
                    </div>
                  );
                })}
            </div>
          )}
        </div>
        <div className="file-upload-share-with">
          {!shareWith.length && (
            <div className="file-upload-share-with-empty">
              Please select contacts
            </div>
          )}
          <div className="file-upload-warning">
            Note: once a file has been shared and the contact has gained access
            this cannot be undone.
          </div>
          {!!shareWith.length &&
            shareWith.map((user, index) => (
              <ShareFile
                key={index}
                contactName={user}
                pubKey={pubKeyB64}
                component={shares.get(user)}
                remove={removeShare}
                updateCost={updateCost}
                title={user}
                id={user}
              />
            ))}
        </div>
        <div className="file-upload-cost-title">
          <div className="file-upload-heading">Cost estimation</div>
          <div className="file-upload-cost-token">
            <img src={wallet} />
            {config.uiCoinDenom.toUpperCase()}
          </div>
        </div>
        <div className="file-upload-cost-detail">
          <div>Transaction fee</div>
          <div>{txFee}</div>
        </div>
        <div className="file-upload-cost-detail">
          <div>Sharing fee</div>
          <div>{reencryptionCost}</div>
        </div>
        <div className="file-upload-cost">
          <div>Total cost</div>
          <div>{totalCost}</div>
        </div>
        <div className="file-upload-container">
          <Button
            title="Share File"
            onClick={(e) => handleSubmit(e)}
            disabled={submitting}
          />
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};
