import { useEffect, useMemo, useState } from "react";
import { Offcanvas, Tab, Tabs } from "react-bootstrap";
import { Id, toast } from "react-toastify";
import { useConfig } from "../../config";
import {
  useDataLazyQuery,
  makeFriendlyPREData,
  usePREReencryptionRequestByDataLazySubscribe,
  usePREReencryptionFragmentsLazyQuery,
  usePREContractQuery,
} from "../../search/pre";
import { humanFileSize, stringFromBase64 } from "../../utils";
import permissionsIcon from "../../assets/permissionsIcon.svg";
import keyIcon from "../../assets/keyIcon.svg";
import audit from "../../assets/audit.svg";
import auditBullet from "../../assets/auditBullet.svg";
import user from "../../assets/user.svg";
import search from "../../assets/search.svg";
import parse from "html-react-parser";
import Fuse from "fuse.js";

import "./DataDetails.scss";
import { useAppSelector } from "../../app/hooks";
import { selectContactsByPubKey } from "../../views/Contacts/contactSlice";

interface DataDetailsProps {
  open: string;
  onClose: () => void;
}

interface AuditTimelineEntry {
  icon: string;
  alt: string;
  text: string;
  blockHeight: number;
  timestamp: Date;
}

export const DataDetails = ({ open, onClose }: DataDetailsProps) => {
  const config = useConfig();
  const [show, setShow] = useState(false);
  const [queryData, data] = useDataLazyQuery(config.preContract, open);
  const [qsubscribeReencReq, reencryptionRequests] =
    usePREReencryptionRequestByDataLazySubscribe(config.preContract, open);
  const [toastId, setToastId] = useState<Id | undefined>();
  const contacts = useAppSelector(selectContactsByPubKey);
  const [queryFragments, fragments] = usePREReencryptionFragmentsLazyQuery(
    config.preContract,
    open,
  );
  const contractInfo = usePREContractQuery(config.preContract);
  const [contactSearch, setContactSearch] = useState("");
  const [calledFor, setCalledFor] = useState("");

  useEffect(() => {
    if (open.length > 0 && calledFor !== open) {
      setShow(true);
      queryData({
        variables: {
          contract: config.preContract,
          data_id: open,
        },
      });
      qsubscribeReencReq(config.preContract, open);
      queryFragments(config.preContract, open);
      setCalledFor(open);
      setToastId(toast.loading("Load file info"));
    }
  }, [
    open,
    config.preContract,
    queryData,
    qsubscribeReencReq,
    data.called,
    queryFragments,
    calledFor,
  ]);

  useEffect(() => {
    if (data.loading === false && toastId !== undefined) {
      toast.dismiss(toastId);
    }
  }, [data.loading, toastId]);

  useEffect(() => {
    if (
      data.error !== undefined ||
      (data.loading === false &&
        data.called &&
        (data.data === undefined || data.data?.pre_data.length !== 1))
    ) {
      console.log("[DataDetails]: Failed to get data: ", data.error, data.data);
      if (toastId !== undefined) {
        toast.error("Failed to get file details", {
          toastId: toastId,
        });
      }
    }
  }, [data.error, toastId, data.data, data.loading, data.called]);

  const friendlyData = useMemo(() => {
    return makeFriendlyPREData(data.data);
  }, [data.data]);

  const handleClose = () => {
    setCalledFor("");
    setShow(false);
    onClose();
  };

  const fragmentCount = useMemo(() => {
    const count = new Map<string, { c: number; available: boolean }>();
    if (
      fragments.data === undefined ||
      fragments.data.pre_reencrypted_fragment.length === 0
    ) {
      return count;
    }
    const threshold = contractInfo.data?.threshold ?? 100;
    fragments.data.pre_reencrypted_fragment.forEach((e) => {
      const c = count.get(e.delegatee_pubkey);
      if (c === undefined) {
        count.set(e.delegatee_pubkey, { c: 1, available: false });
        return;
      }
      count.set(e.delegatee_pubkey, {
        available: c.c + 1 >= threshold,
        c: c.c + 1,
      });
    });
    return count;
  }, [fragments.data, contractInfo.data]);

  const timeLine = useMemo(() => {
    if (friendlyData === undefined || friendlyData.length !== 1) {
      return [] as AuditTimelineEntry[];
    }
    let timeline: AuditTimelineEntry[] = [];
    if (
      reencryptionRequests.data !== undefined &&
      reencryptionRequests.data.pre_reencryption_requests !== undefined &&
      reencryptionRequests.data.pre_reencryption_requests.length > 0
    ) {
      // prettier-ignore
      reencryptionRequests.data.pre_reencryption_requests.forEach((v) => {
        timeline.push({
          icon: auditBullet,
          alt: "share requested",
          text: `Requested to share file with 
            <b>
              ${
  contacts[v.delegatee_pubkey] === undefined ?
    v.delegatee_pubkey.slice(0, 15) + "..." :
    contacts[v.delegatee_pubkey].name}
            </b>.`,
          blockHeight: v.height,
          timestamp: new Date(v.block.timestamp),
        });
      });
    }
    if (
      fragments.data !== undefined &&
      fragments.data.pre_reencrypted_fragment.length > 0
    ) {
      const proxies = new Map<string, string[]>();
      // prettier-ignore
      fragments.data.pre_reencrypted_fragment.forEach((e) => {
        const c = fragmentCount.get(e.delegatee_pubkey) ?? {
          c: 0,
          available: false,
        };
        if ((proxies.get(e.delegatee_pubkey) ?? []).includes(e.proxy_addr) === true) {
          return;
        }
        proxies.set(e.delegatee_pubkey, [e.proxy_addr, ...(proxies.get(e.delegatee_pubkey) ?? [])]);
        timeline.push({
          icon: auditBullet,
          alt: "data fragment reencrypted",
          text: `${e.proxy_addr.slice(
            0,
            20,
          )}... proxy provided reencrypted fragment for sharing data with
            <b>
              ${
  contacts[e.delegatee_pubkey] === undefined ? e.delegatee_pubkey.slice(0, 10)+"..." :
    contacts[e.delegatee_pubkey].name
}
            </b>. ${c.available === true ? ` Fragment threshold met. Data is available from now for
              <b>
                ${
  contacts[e.delegatee_pubkey] === undefined ? e.delegatee_pubkey.slice(0, 10)+"..." :
    contacts[e.delegatee_pubkey].name
}
              </b>.` : ""}`,
          blockHeight: e.height,
          timestamp: new Date(e.block.timestamp),
        });
      });
      console.log("PROXIES: ", proxies);
    }
    timeline = timeline.sort((a, b) => b.blockHeight - a.blockHeight);
    timeline.push({
      icon: auditBullet,
      alt: "file upload",
      text: `File <b>${stringFromBase64(
        friendlyData[0].tags.get("title"),
      )}</b> added.`,
      blockHeight: friendlyData[0].height,
      timestamp: new Date(friendlyData[0].block.timestamp),
    });
    return timeline;
  }, [
    friendlyData,
    reencryptionRequests.data,
    contacts,
    fragments.data,
    fragmentCount,
  ]);

  const fragmentShare = useMemo(() => {
    if (fragments.data === undefined) return [];
    const arr: string[] = [];
    fragments.data.pre_reencrypted_fragment.forEach((v) => {
      arr.push(v.delegatee_pubkey);
    });
    return arr;
  }, [fragments.data]);

  const shared = useMemo(() => {
    const rrenc =
      reencryptionRequests?.data?.pre_reencryption_requests?.map(
        (rr) => rr.delegatee_pubkey,
      ) ?? [];
    return Array.from(new Set([...fragmentShare, ...rrenc])).map((v) => ({
      pubKey: v,
      name: contacts[v] === undefined ? "" : contacts[v].name,
    }));
  }, [fragmentShare, reencryptionRequests, contacts]);

  const filteredShared = useMemo(() => {
    if (contactSearch === "") return shared;
    const options = {
      includeScore: true,
      keys: ["name", "pubKey"],
    };

    const c = Object.values(shared);
    const fuse = new Fuse(c, options);

    const result = fuse.search(contactSearch);

    return result
      .filter((v) => v.score !== undefined && v.score < 0.1)
      .map((v) => v.item);
  }, [shared, contactSearch]);

  // prettier-ignore
  return (
    <Offcanvas
      show={show}
      onHide={handleClose}
      className="file-upload"
      size="lg"
      placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Share file</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="outlinedTextBox">
          {friendlyData !== undefined && friendlyData.length > 0 && (
            stringFromBase64(friendlyData[0].tags.get("title"))
          )}
        </div>
        <div className="outlinedTextBoxUnderText">
          {friendlyData !== undefined && friendlyData.length > 0 && (
            `${friendlyData[0].tags.get("type") !== null ? friendlyData[0].tags.get("type")+"," : ""}
             ${humanFileSize(Number.parseInt(friendlyData[0].tags.get("size") ?? ""))},
             ${(new Date(friendlyData[0].block.timestamp)).toLocaleString()}`
          )}
        </div>
        <Tabs defaultActiveKey="audit" className="tabs">
          <Tab eventKey="share" title={
            <div className="detailsTabTitle">
              <img src={permissionsIcon} alt="share permissions" />
              <p>Shared permissions</p>
            </div>
          }>
            <div className="contactSearch">
              <img src={search} />
              <input
                placeholder="Search contacts..."
                value={contactSearch}
                onChange={(e) => setContactSearch(e.target.value)}
              />
            </div>
            <div className="dataDetailsSharedWith">
              <div className="my-files-shared-with">
                {filteredShared.map((s, index) => (
                  <div key={index} className="my-files-shared-with-name">
                    <img src={user} alt="person icon" />
                    <span>{s.name.length > 0 ? s.name : s.pubKey.slice(0, 15) + "..."}</span>
                    {fragmentCount.get(s.pubKey)?.available === true && (
                      <img className="keyIcon" src={keyIcon} alt="key icon" />
                    )}
                  </div>
                ))}
              </div>
            </div>
          </Tab>
          <Tab eventKey="audit" title={
            <div className="detailsTabTitle">
              <img src={audit} alt="Audit timeline" />
              <p>Audit timeline</p>
            </div>
          }>
            <div className="dataDetailsAuditBox">
              {timeLine?.map((e, index) => (
                <div key={`timeline_entry_${index}`}>
                  <img src={e.icon} alt={e.alt}/>
                  <p>{parse(e.text)}
                    Block height: 
                    {config.blockExplorer === undefined && (
                      e.blockHeight
                    )}
                    {config.blockExplorer !== undefined && (
                      <a href={config.blockExplorer+e.blockHeight.toString()}>{e.blockHeight}</a>
                    )},
                    time: {e.timestamp.toLocaleString()}
                  </p>
                </div>
              ))}
            </div>
          </Tab>
        </Tabs>
      </Offcanvas.Body>
    </Offcanvas>
  );
};
