import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { CONNECT_TEXT, DISCONNECT_TEXT, ONBOARD_TEXT } from "./text";
import { AccountType, selectAccount, setAccounts } from "./walletSlice";
import { useContract } from "../../app/cosmosServices";
import { useConfig } from "../../config";
import { toBase64, toHex } from "@cosmjs/encoding";
import { addContact } from "../../views/Contacts/contactSlice";
import wallet from "../../assets/walletWhite.svg";
import { Button } from "../Button";
import {
  clearToken,
  selectSearchSlice,
  triggerSearchConnect,
} from "../../search/searchSlice";

declare let window: Window;

export const Keplr = () => {
  const [buttonText, setButtonText] = useState(CONNECT_TEXT);
  const [isInstall, setIsInstall] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const contract = useContract();
  const config = useConfig();
  const account = useAppSelector(selectAccount);
  const searchState = useAppSelector(selectSearchSlice);

  const [newAcc, setNewAcc] = useState<AccountType | undefined>();

  useEffect(() => {
    if (account === undefined && buttonText === DISCONNECT_TEXT) {
      setButtonText(CONNECT_TEXT);
    }
  }, [account, buttonText]);

  const loadWallet = async () => {
    if (config.initialized === false) return;
    if (window.fetchBrowserWallet === undefined) {
      setButtonText(ONBOARD_TEXT);
      setIsInstall(true);
      toast.error(
        "No supported wallet installed on this browser. Please install Fetch.AI wallet!",
      );
    } else {
      try {
        if (config.walletConfig !== null) {
          await window.fetchBrowserWallet.keplr.experimentalSuggestChain(
            config.walletConfig,
          );
        }
        await window.fetchBrowserWallet.keplr.enable(config.chainId);
        await window.fetchBrowserWallet.keplr.enable(config.chainId);
      } catch (error) {
        console.log("[FetchWalet] wallet not initialized: ", error);
        toast.error("Wallet not initialized!");
        return;
      }

      try {
        const offlineSigner = window.fetchBrowserWallet.keplr.getOfflineSigner(
          config.chainId,
        );
        const accounts = await offlineSigner.getAccounts();
        const p = await window.fetchBrowserWallet.umbral.getPublicKey(
          config.chainId,
        );
        const pB64 = toBase64(p);

        await contract.init(offlineSigner);

        const account = {
          address: accounts[0].address,
          pubkey: toHex(accounts[0].pubkey),
          pubkeyB64: toBase64(accounts[0].pubkey),
          umbralPubKeyB64: pB64,
        };

        dispatch(triggerSearchConnect(account));
        setNewAcc(account);
      } catch (error) {
        console.log("Failed to establish connection: ", error);
      }
    }
  };

  useEffect(() => {
    if (
      newAcc === undefined ||
      searchState.authToken === undefined ||
      searchState.authToken.length === 0
    ) {
      return;
    }
    dispatch(setAccounts([newAcc]));

    dispatch(
      addContact({
        name: "Myself",
        pubKey: newAcc.umbralPubKeyB64,
        notes: "",
      }),
    );

    setButtonText(DISCONNECT_TEXT);
  }, [newAcc, searchState.authToken, dispatch]);

  const onClick = () => {
    if (isInstall) {
      window.location.replace(config.walletInstallLink);
      navigate("/install-wallet");
      return;
    }
    if (account === undefined) {
      loadWallet();
      return;
    }
    dispatch(clearToken());
    window.location.href = config.landingURL;
  };

  return <Button onClick={onClick} icon={wallet} title={buttonText} />;
};
