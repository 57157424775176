import {
  DragEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Offcanvas } from "react-bootstrap";
import classNames from "classnames";

import { add, formatSizeUnits } from "../../config/utils";
import { useAppSelector } from "../../app/hooks";
import upload from "../../assets/drag-upload.svg";
import trash from "../../assets/trash.svg";
import fileIcon from "../../assets/file.svg";
import right from "../../assets/right-circle.svg";
import wallet from "../../assets/walletWhite.svg";

import "./UploadFile.scss";
import { fromBase64, toBase64 } from "@cosmjs/encoding";
import { selectAccount } from "../Keplr/walletSlice";
import {
  ExecuteCoins,
  ExecuteMsgs,
  tag,
  usePREContract,
} from "../../app/cosmosServices";
import { useConfig } from "../../config";
import { create } from "ipfs-http-client";
import { UmbralEncryptionResult } from "@fetchai/umbral-types";
import { ShareFile, ShareResponse, TriggerShare } from "./Share";
import { selectContacts } from "../../views/Contacts/contactSlice";
import search from "../../assets/search.svg";
import { toast } from "react-toastify";

import Fuse from "fuse.js";
import { snooze, stringToBase64 } from "../../utils";
import { useCosmWasmClient } from "../../app/cosmosServices/ContractProvider";
import { multiSimulate } from "../../app/cosmosServices/utils";
import { Button } from "../Button";

declare let window: Window;

interface EncryptedFile {
  encrypted: Uint8Array;
  encryptedKey: UmbralEncryptionResult;
  originalSize: number;
  originalType: string;
}

interface UploadFileProps {
  show: boolean;
  setShow: (show: boolean) => void;
  pubKeyB64: string;
}

function readFile(file: Blob): Promise<Uint8Array> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.addEventListener("loadend", (e) => {
      if (e.target === null) {
        resolve(new Uint8Array(0));
        return;
      }
      resolve(new Uint8Array(e.target.result as ArrayBuffer));
    });
    reader.addEventListener("error", reject);

    reader.readAsArrayBuffer(file);
  });
}

const UploadFile = ({ show, setShow, pubKeyB64 }: UploadFileProps) => {
  const [shareWith, setShareWith] = useState<string[]>([]);
  const [dragging, setDragging] = useState<boolean>(false);
  const [file, setFile] = useState<File>();
  const inputFile = useRef<HTMLInputElement>(null);
  const pubKey = fromBase64(pubKeyB64);
  const account = useAppSelector(selectAccount);
  const contract = usePREContract();
  const cosmWasmClient = useCosmWasmClient();
  const config = useConfig();
  const [encryptedFile, setEncryptedFile] = useState<EncryptedFile>();
  const [submitting, setSubmitting] = useState(false);
  const [contactSearch, setContactSearch] = useState<string>("");
  const contacts = useAppSelector(selectContacts);
  const [shares, setShares] = useState(new Map<string, TriggerShare>());
  const [costs, setCosts] = useState(new Map<string, number>());
  const [txFee, setTxFee] = useState(0);

  useEffect(() => {
    if (shareWith.length === 0) {
      setShares(new Map<string, TriggerShare>());
      setCosts(new Map<string, number>());
      return;
    }
    setShares((p) => {
      const m = new Map<string, TriggerShare>();
      shareWith.forEach((name) => {
        m.set(
          name,
          p.get(name) ?? {
            share: async (
              dataId: string,
            ): Promise<ShareResponse | undefined> => {
              return;
            },
          },
        );
      });
      return m;
    });
    setCosts((p) => {
      const m = new Map<string, number>();
      shareWith.forEach((name) => {
        m.set(name, p.get(name) ?? 0);
      });
      return m;
    });
  }, [shareWith]);

  const validated =
    encryptedFile !== undefined &&
    encryptedFile?.encrypted.length > 0 &&
    encryptedFile?.encryptedKey.cipherText.length > 0;

  useEffect(() => {
    if (file === undefined) return;
    if (encryptedFile !== undefined) return;
    let active = true;
    if (file.size > 5 * 1024 * 1024) {
      toast.error("File bigger then limit (5MB)!");
      setFile(undefined);
      return;
    }
    const encryptionPromisse = (async function () {
      if (file === undefined) return;
      if (pubKey.length !== 33) return;
      const fileContent = await readFile(file);
      const aesKey = await window.crypto.subtle.generateKey(
        {
          name: "AES-GCM",
          length: 256,
        },
        true,
        ["encrypt", "decrypt"],
      );
      const iv = window.crypto.getRandomValues(new Uint8Array(12));
      const keyBuffer = await window.crypto.subtle.exportKey("raw", aesKey);
      const keyView = new Uint8Array(keyBuffer);
      const aesBucket = new Uint8Array(iv.length + keyView.length);
      aesBucket.set(iv, 0);
      aesBucket.set(keyView, iv.length);
      const encryptedKey = await window.fetchBrowserWallet?.umbral.encrypt(
        pubKey,
        aesBucket,
      );
      const encryptedData = await window.crypto.subtle.encrypt(
        {
          name: "AES-GCM",
          iv,
          tagLength: 128,
        },
        aesKey,
        fileContent,
      );
      const encrypted = new Uint8Array(encryptedData);
      if (!active) return;
      setEncryptedFile({
        encrypted,
        encryptedKey,
        originalSize: file.size,
        originalType: file.type,
      });
    })();
    encryptionPromisse.catch((err) => {
      console.log("Failed to load/encrypt file: ", err);
    });
    toast.promise(encryptionPromisse, {
      pending: "Loading and encrypting file...",
      success: "File encrypted",
      error: "Failed to load/encrypt file",
    });
    return () => {
      active = false;
    };
  }, [file, pubKey, encryptedFile]);

  const handleSubmit = async (e: any) => {
    e.stopPropagation();
    if (window.fetchBrowserWallet?.umbral === undefined) return;
    if (validated === false) return;
    if (encryptedFile === undefined) return;
    if (contract === undefined) return;
    if (submitting === true) return;
    const promise = (async function () {
      try {
        setSubmitting(true);
        const client = create({ url: config.ipfsURI });
        const resp = await client.add(encryptedFile.encrypted);

        const capsule = toBase64(encryptedFile.encryptedKey.capsule);
        const tags = [
          tag("title", stringToBase64(file?.name)),
          tag("size", encryptedFile.originalSize.toString()),
          tag("type", encryptedFile.originalType),
          tag("key", toBase64(encryptedFile.encryptedKey.cipherText)),
        ];
        const sharesArray = Array.from(shares);
        if (sharesArray.length === 0) {
          const hash = await contract.addData(
            account.address,
            resp.path,
            pubKeyB64,
            capsule,
            tags,
          );
          console.log("Data added: ", hash);
        } else {
          const preDataMsgs: ExecuteMsgs = [];
          const preDataCoins: ExecuteCoins = [];
          const postDataMsgs: ExecuteMsgs = [];
          const postDataCoins: ExecuteCoins = [];
          for (const [key, value] of sharesArray) {
            const s = await value.share(resp.path);
            if (s === undefined) {
              console.log(`Failed to share file ${resp.path} with ${key}!`);
              continue;
            }
            if (s.delegationMsg !== undefined) {
              preDataMsgs.push(s.delegationMsg);
              preDataCoins.push(undefined);
            }
            postDataMsgs.push(s.reencryptionMsg);
            postDataCoins.push(s.reencryptionCoins);
          }
          const hash = await contract.addDataAndShare(
            account.address,
            resp.path,
            pubKeyB64,
            capsule,
            tags,
            preDataMsgs,
            preDataCoins,
            postDataMsgs,
            postDataCoins,
          );
          console.log("Data added: ", hash);
        }
      } catch (error) {
        console.log("AddDataForm: failed to submit data: ", error);
        throw error;
      } finally {
        setSubmitting(false);
        setFile(undefined);
        setShareWith([]);
        setEncryptedFile(undefined);
        setContactSearch("");
        handleClose();
      }
    })();
    toast.promise(promise, {
      pending: "Uploading file",
      success: "File added!",
      error: "Failed to add file",
    });
  };

  useEffect(() => {
    if (encryptedFile === undefined) return;
    if (cosmWasmClient === undefined) return;
    if (account === undefined) return;
    (async function () {
      if (encryptedFile === undefined) return;
      if (cosmWasmClient === undefined) return;
      const sharesArray = Array.from(shares);
      const path = (Math.random() + 1).toString(32);
      const capsule = toBase64(encryptedFile.encryptedKey.capsule);
      const tags = [
        tag("title", stringToBase64(file?.name)),
        tag("size", "1024"),
        tag("type", "text/json"),
        tag("key", toBase64(encryptedFile.encryptedKey.cipherText)),
      ];
      const addData = contract?.prepareAddData(path, pubKeyB64, capsule, tags);
      if (addData === undefined) return;
      let msgs: ExecuteMsgs = [];
      let coins: ExecuteCoins = [];
      if (sharesArray.length >= 0) {
        const preDataMsgs: ExecuteMsgs = [];
        const postDataMsgs: ExecuteMsgs = [];
        const preDataCoins: ExecuteCoins = [];
        const postDataCoins: ExecuteCoins = [];
        for (const [, value] of sharesArray) {
          let s = await value.share(path);
          while (s === undefined) {
            await snooze(100);
            s = await value.share(path);
          }
          if (s.delegationMsg !== undefined) {
            preDataMsgs.push(s.delegationMsg);
            preDataCoins.push(undefined);
          }
          postDataMsgs.push(s.reencryptionMsg);
          postDataCoins.push(s.reencryptionCoins);
        }
        msgs = [...preDataMsgs, addData, ...postDataMsgs];
        coins = [...preDataCoins, undefined, ...postDataCoins];
      } else {
        msgs = [addData];
        coins = [undefined];
      }
      try {
        const fees = await multiSimulate(
          cosmWasmClient,
          config,
          account.address,
          config.preContract,
          msgs,
          coins,
        );
        for (const fee of fees.amount) {
          if (fee.denom === config.coinDenom) {
            setTxFee(config.toUICoin(Number.parseFloat(fee.amount)));
            break;
          }
        }
      } catch (e) {
        // no action
      }
    })();
  }, [
    shares,
    encryptedFile,
    cosmWasmClient,
    account,
    contract,
    file?.name,
    pubKeyB64,
    config.preContract,
    config,
  ]);

  const handleClose = () => setShow(false);

  const dropHandler = (ev: DragEvent) => {
    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();
    setDragging(false);

    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      if (ev.dataTransfer.items.length !== 1) {
        toast.error("Only one file can be uploaded!");
        return;
      }
      // If dropped items aren't files, reject them
      if (ev.dataTransfer.items[0].kind === "file") {
        const file = ev.dataTransfer.items[0].getAsFile();
        if (file !== null) {
          setFile(file);
        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      if (ev.dataTransfer.files.length !== 1) {
        toast.error("Only one file can be uploaded!");
        return;
      }
      const file = ev.dataTransfer.files[0];
      setFile(file);
    }
  };

  const dragOverHandler = (ev: DragEvent) => {
    setDragging(true);
    ev.preventDefault();
  };

  const dragLeaveHandler = (ev: DragEvent) => {
    setDragging(false);
    ev.preventDefault();
  };

  const removeShare = useCallback(
    (user: string) =>
      setShareWith((p) => p.filter((filterUser) => filterUser !== user)),
    [],
  );

  const updateCost = useCallback((user: string, cost: number) => {
    setCosts((p) => {
      p.set(user, cost);
      return new Map(p);
    });
  }, []);

  const filteredContacts = useMemo(() => {
    if (contactSearch === "") return [];
    const options = {
      includeScore: true,
      keys: ["name", "notes", "pubKey"],
    };

    const c = Object.values(contacts);
    const fuse = new Fuse(c, options);

    const result = fuse.search(contactSearch);

    return result
      .filter((v) => v.score !== undefined && v.score < 0.1)
      .map((v) => v.item);
  }, [contacts, contactSearch]);

  const reencryptionCost = useMemo(() => {
    return config.toUICoin(
      Array.from(costs)
        .map((v) => v[1])
        .reduce((p, c) => p + c, 0),
    );
  }, [costs, config]);

  const totalCost = useMemo(() => {
    return add(reencryptionCost, txFee);
  }, [reencryptionCost, txFee]);

  return (
    <Offcanvas
      show={show}
      onHide={handleClose}
      className="file-upload"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>
          <span className="file-upload-heading">Upload file and share</span>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <span className="file-upload-details">
          Upload a file to your list and optionally select contacts to share it
          with.
          <div className={"bold"}>
            You will need to approve the upload and sharing fees when prompted
            by your wallet.
          </div>
          <div>
            See{" "}
            <a
              href="#"
              data-bs-toggle="tooltip"
              title="Fees are paid to the blockchain network in order to execute transactions.
                    Additionally there is a fee paid to the proxies for re-encrypting the file for the person you
                    are sharing with. These fees will be displayed below.">
              gas fee
            </a>{" "}
            information.
          </div>
        </span>
        <br></br>
        <div
          className={classNames("file-upload-drag-drop", {
            "file-upload-drag-drop--dragging": dragging,
          })}
          onDrop={dropHandler}
          onDragOver={dragOverHandler}
          onDragLeave={dragLeaveHandler}
          onClick={() => inputFile.current?.click()}>
          <input
            type="file"
            id="file"
            ref={inputFile}
            style={{ display: "none" }}
            onChange={(event) => {
              if (event.target.files?.length) {
                setFile(event.target.files[0]);
              }
            }}
          />
          <div className="file-upload-drag-drop-icon">
            <img src={upload} />
          </div>
          <div className="file-upload-drag-drop-description">
            Click to upload or drag and drop
          </div>
          <div className="file-upload-drag-drop-size">(max 5MB)</div>
        </div>
        <div className="file-upload-attached-files">
          <div className="file-upload-heading">Attached files</div>
          <div className="file-upload-attached-files-list">
            {!file ? (
              <div className="file-upload-attached-files-list-empty">
                Please upload file
              </div>
            ) : (
              <>
                <div className="file-upload-attached-files-list-details">
                  <div className="file-upload-attached-files-list-details-icon">
                    <img src={fileIcon} />
                  </div>
                  <div className="file-upload-attached-files-list-details-info">
                    <div>{file.name}</div>
                    <div>{formatSizeUnits(file.size)}</div>
                  </div>
                  <div className="file-upload-attached-files-list-details-delete">
                    <img
                      src={trash}
                      onClick={() => {
                        setFile(undefined);
                        setEncryptedFile(undefined);
                      }}
                    />
                  </div>
                </div>
                <div className="file-upload-attached-files-list-progress">
                  <div className="file-upload-attached-files-list-progress-line"></div>
                  <div className="file-upload-attached-files-list-progress-percentage">
                    {encryptedFile !== undefined ? "100" : "25"}%
                  </div>
                  <div className="file-upload-attached-files-list-progress-icon">
                    <img src={right} />
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="file-upload-attached-files-description">
            File {file?.name} will appear in your ‘My Files’ list
          </div>
        </div>
        <div className="file-upload-heading">Share file with</div>
        <div className="file-upload-search">
          <div>
            <img src={search} />
            <input
              placeholder="Search contacts..."
              value={contactSearch}
              onChange={(e) => setContactSearch(e.target.value)}
            />
          </div>
          {filteredContacts.length > 0 && (
            <div className="file-upload-search-list">
              {filteredContacts
                .filter((contact: any) => !shareWith.includes(contact.name))
                .map((contact: any) => {
                  return (
                    <div
                      key={contact.name}
                      className="file-upload-search-list-contact"
                      onClick={() =>
                        setShareWith([...shareWith, contact.name])
                      }>
                      {contact.name}
                    </div>
                  );
                })}
            </div>
          )}
        </div>
        <div className="file-upload-share-with">
          {!shareWith.length && (
            <div className="file-upload-share-with-empty">
              Please select a contact
            </div>
          )}
          {!!shareWith.length &&
            shareWith.map((user, index) => (
              <ShareFile
                key={index}
                contactName={user}
                pubKey={pubKeyB64}
                component={shares.get(user)}
                remove={removeShare}
                updateCost={updateCost}
                title={user}
                id={user}
              />
            ))}
        </div>
        <div className="file-upload-warning">
          Note: once a file has been shared and the contact has gained access
          this cannot be undone.
        </div>
        <div className="file-upload-cost-title">
          <div className="file-upload-heading">Cost estimation</div>
          <div className="file-upload-cost-token">
            <img src={wallet} />
            {config.uiCoinDenom.toUpperCase()}
          </div>
        </div>
        <div className="file-upload-cost-detail">
          <div>Transaction fee</div>
          <div>{txFee}</div>
        </div>
        <div className="file-upload-cost-detail">
          <div>Sharing fee</div>
          <div>{reencryptionCost}</div>
        </div>
        <div className="file-upload-cost">
          <div>Total cost</div>
          <div>{totalCost}</div>
        </div>
        <div className="file-upload-container">
          <Button
            title={
              shareWith.length > 0 ? "Upload and Share File" : "Upload File"
            }
            onClick={handleSubmit}
            disabled={submitting}
          />
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default UploadFile;
