import { AppConfig, CoinMapValue } from "./types";
import { coinToUICoin, getApiBackoffConfig, getWalletConfig } from "./utils";
import CoinMap from "../.coinmap.json";

export function parseConfigFromEnv(value: AppConfig): AppConfig {
  const coinsUntyped = new Map(Object.entries(CoinMap));
  const coins = new Map<string, CoinMapValue>();
  coinsUntyped.forEach((v, k) => {
    coins.set(k, v as CoinMapValue);
  });

  let config: AppConfig = {
    ...value,
    chainName: process.env.REACT_APP_CHAIN_NAME!,
    chainId: process.env.REACT_APP_CHAIN_ID!,
    rpcAddress: process.env.REACT_APP_CHAIN_RPC!,
    restAddress: process.env.REACT_APP_CHAIN_REST!,
    addressPrefix: process.env.REACT_APP_ADDRESS_PREFIX!,
    feeToken: process.env.REACT_APP_FEE_TOKEN!,
    gasPrice: Number.parseInt(process.env.REACT_APP_GAS_PRICE!, 10),
    walletInstallLink: process.env.REACT_APP_INSTALL_WALLET_URL!,
    coinMap: coins,
    authServiceURI: process.env.REACT_APP_AUTH_SERVICE_URI!,
    graphqlHttpURI: process.env.REACT_APP_GRAPHQL_HTTP_URI!,
    graphqlWsURI: process.env.REACT_APP_GRAPHQL_WS_URI!,
    ipfsURI: process.env.REACT_APP_IPFS_URI!,
    apiBackoff: getApiBackoffConfig(value),
    coinDenom: process.env.REACT_APP_COIN_DENOM!,
    uiCoinDenom: process.env.REACT_APP_UI_COIN_DENOM!,
    preContract: process.env.REACT_APP_PRE_CONTRACT!,
    expireTimeMS:
      Number.parseInt(process.env.REACT_APP_EXPIRE_TIME_IN_HOURS!, 10) *
      60 *
      60 *
      1000,
    expireTimestamp: process.env.REACT_APP_EXPIRE_TIMESTAMP!,
    blockExplorer: process.env.REACT_APP_BLOCK_EXPLORER!,
    faucetURL: process.env.REACT_APP_FAUCET_URL!,
    landingURL: process.env.REACT_APP_LANDING_URL!,
  };

  const coin = config.coinMap.get(config.coinDenom.toLowerCase());
  if (coin === undefined) {
    console.log(
      `coinToUICoin: coinDenom (${config.coinDenom}) not in coinMap: ${config.coinMap}`,
    );
    throw Error("bad coinDenom");
  }
  if (coin.denom.toLowerCase() !== config.uiCoinDenom.toLowerCase()) {
    throw Error("Mismatching coinDenom and uiCoinDenom");
  }

  const walletConfig = getWalletConfig(config);
  config = {
    ...config,
    coin: coin,
    walletConfig: walletConfig,
  };
  return {
    ...config,
    toUICoin: (coins: number) => coinToUICoin(config, coins),
  };
}
