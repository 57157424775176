import { PageHeader } from "../../components/PageHeader";
import { SharedDataTable } from "../../components/Data/SharedDataTable";

import "./SharedFiles.scss";
import { useAppSelector } from "../../app/hooks";
import { selectAccount } from "../../components/Keplr/walletSlice";

const SharedFiles = () => {
  const account = useAppSelector(selectAccount);

  return (
    <div className="shared-files">
      <div className="shared-files-header">
        <PageHeader
          heading="Shared Files"
          details="Files that have been shared with me."
        />
        <SharedDataTable
          pubKey={account?.umbralPubKeyB64 ?? ""}></SharedDataTable>
      </div>
    </div>
  );
};

export default SharedFiles;
