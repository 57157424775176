import { MouseEvent } from "react";
import classNames from "classnames";

import "./Button.scss";

interface ButtonProps {
  onClick: (e: MouseEvent<HTMLElement>) => void;
  icon?: string;
  title: string;
  disabled?: boolean;
  disabledHover?: boolean;
}

const Button = (props: ButtonProps) => {
  return (
    <button
      className={classNames("button", {
        "button--disabled": props.disabled,
        "button--disabled-hover": props.disabledHover,
      })}
      disabled={props.disabled}
      onClick={props.onClick}>
      {props.icon && <img src={props.icon}></img>}
      <span style={{ marginLeft: props.icon ? "8px" : "0px" }}>
        {props.title}
      </span>
    </button>
  );
};

export default Button;
