import { PageHeader } from "../../components/PageHeader";
import { DataTable } from "../../components/DataTable";
import createContact from "../../assets/create-contact.svg";
import { ContactForm } from "../../components/ContactForm";
import { addContact, removeContact, selectContacts } from "./contactSlice";

import Fuse from "fuse.js";

import "./Contacts.scss";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useCallback, useMemo, useState } from "react";
import { Checkbox } from "../../components/Checkbox";
import trash from "../../assets/trash.svg";
import { selectSearchBarText } from "../../components/AppHeader/searchBarSlice";
import { ShareWithContact } from "../../components/ContactForm/ShareWithContact";
import { selectAccount } from "../../components/Keplr/walletSlice";

const Contacts = () => {
  const header = [
    { label: "Name", key: "name" },
    { label: "Public Key", key: "pubKey" },
    { label: "Notes", key: "notes" },
    { label: "Share file with contact", key: "shareFile" },
  ];
  const contacts = useAppSelector(selectContacts);
  const account = useAppSelector(selectAccount);
  const dispatch = useAppDispatch();

  const [show, setShow] = useState(false);

  const [selectAll, setSelectAll] = useState(false);
  const [selection, setSelection] = useState(new Map<string, boolean>());

  const selected = useMemo(() => {
    return Array.from(selection.entries()).filter((v) => v[1] === true);
  }, [selection]);

  const searchBarText = useAppSelector(selectSearchBarText);

  const handleSelectAll = (value: boolean) => {
    const sel = new Map<string, boolean>();
    Object.keys(contacts).forEach((v) => {
      sel.set(v, value);
    });
    setSelectAll(value);
    setSelection(sel);
  };

  const changeSelection = (key: string, value: boolean) => {
    setSelection((prev) => {
      prev.set(key, value);
      return new Map(prev);
    });
  };

  const removeContacts = useCallback(() => {
    const removed = new Set<string>();
    selected.forEach((k) => {
      if (k[1] === false) {
        return;
      }
      dispatch(removeContact(k[0]));
      removed.add(k[0]);
    });
    setSelection((p) => {
      const m = new Map<string, boolean>();
      p.forEach((v, k) => {
        if (removed.has(k)) return;
        m.set(k, v);
      });
      return m;
    });
  }, [selected, dispatch]);

  const searchedData = useMemo(() => {
    const c = Object.values(contacts);
    if (searchBarText === "") return c;
    const options = {
      includeScore: true,
      keys: ["name", "notes", "pubKey"],
    };

    const fuse = new Fuse(c, options);

    const result = fuse.search(searchBarText);

    return result
      .filter((v) => v.score !== undefined && v.score < 0.1)
      .map((v) => v.item);
  }, [contacts, searchBarText]);

  return (
    <div className="contacts">
      <div className="contacts-header">
        <PageHeader
          heading="Contacts"
          details="Create contacts."
          onAction={() => {
            setShow(true);
          }}
          actionText="Create Contact"
          actionIcon={createContact}
        />
      </div>
      {/*  todo: duplicated from DataViewer. Make component. */}
      <div className="selectDelete">
        <Checkbox value={selectAll} onChange={handleSelectAll} />
        <div className="selectDeleteLabel">Select All</div>
        {selected.length > 0 && (
          <>
            <div className="selectDeleteTrash">{selected.length} selected</div>
            <img src={trash} onClick={removeContacts} alt="Delete files" />
          </>
        )}
        <div id="fileCount">{Object.keys(contacts).length} contacts</div>
      </div>
      <DataTable
        selectable={true}
        header={header}
        data={searchedData.map((d) => ({
          ...d,
          selectionId: d.name,
          select: selection.get(d.name) ?? false,
          changeSelect: changeSelection,
          shareFile: (
            <div>
              <ShareWithContact
                contact={d.name}
                pubKey={account?.umbralPubKeyB64 ?? ""}></ShareWithContact>
            </div>
          ),
        }))}
      />
      <ContactForm
        show={show}
        setShow={setShow}
        addContact={(name: string, addr: string, notes: string) =>
          dispatch(
            addContact({
              name: name,
              pubKey: addr,
              notes: notes,
            }),
          )
        }
      />
    </div>
  );
};

export default Contacts;
