import "./UploadFile.scss";

import { useEffect, useMemo, useState } from "react";
import {
  usePREContract,
  ExecuteMsg,
  ExecuteCoin,
} from "../../app/cosmosServices";
import { useAppSelector } from "../../app/hooks";
import { selectAccount } from "../Keplr/walletSlice";
import { useConfig } from "../../config";
import {
  useActiveProxiesLazyQuery,
  useDelegatorProxiesQuery,
  usePREContractQuery,
} from "../../search/pre";
import { isBase64 } from "../../utils";
import { fromBase64 } from "@cosmjs/encoding";
import { selectContacts } from "../../views/Contacts/contactSlice";
import { createDelegation } from "../../app/cosmosServices/utils";
import address from "../../assets/address.svg";
import trash from "../../assets/trash.svg";
import { toast } from "react-toastify";

export interface ShareResponse {
  reencryptionMsg: ExecuteMsg;
  reencryptionCoins: ExecuteCoin;
  delegationMsg?: ExecuteMsg;
}
export interface TriggerShare {
  share: (dataId: string) => Promise<ShareResponse | undefined>;
}

interface ShareFileProps {
  pubKey: string;
  contactName: string;
  title: string;
  id: string;
  remove: (id: string) => void;
  updateCost: (id: string, cost: number) => void;
  component: TriggerShare | undefined;
}

export const ShareFile = ({
  pubKey,
  contactName,
  title,
  id,
  remove,
  updateCost,
  component,
}: ShareFileProps) => {
  const account = useAppSelector(selectAccount);
  const contract = usePREContract();
  const config = useConfig();
  const [loading, setLoading] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [getDelegations, delegations, subscribeDelegation] =
    useDelegatorProxiesQuery();
  const [getAvailableProxies, availableProxyResponse] =
    useActiveProxiesLazyQuery();
  const contractInfo = usePREContractQuery(config.preContract);
  const contacts = useAppSelector(selectContacts);
  const [delegationCreate, setDelegationCreate] = useState<
    ExecuteMsg | undefined
  >();
  const [selectedProxies, setSelectedProxies] = useState<number>(0);

  if (contractInfo.error !== undefined) {
    console.log("[Share]: Failed to get contract info: ", contractInfo.error);
  }

  const reader = useMemo(() => {
    if (contactName.length === 0) return "";
    return contacts[contactName].pubKey;
  }, [contactName, contacts]);

  const readerValid =
    reader.length > 0 &&
    isBase64(reader) === true &&
    fromBase64(reader).length === 33;

  const delegationFound =
    delegations.data !== undefined &&
    delegations.data.pre_delegation !== undefined &&
    delegations.data.pre_delegation.length === 1;

  useEffect(() => {
    if (config.preContract === undefined) return;
    if (pubKey === undefined || isBase64(pubKey) === false) return;
    if (readerValid === false) return;
    if (delegations.loading === true) return;
    if (delegations.called) {
      if (
        !delegationFound ||
        delegations.data?.pre_delegation[0].delegatee_pubkey === reader ||
        delegations.variables?.delegatee === reader
      ) {
        return;
      }
    }
    getDelegations(config.preContract, pubKey, reader);
    setInProgress(false);
  }, [
    config.preContract,
    pubKey,
    reader,
    getDelegations,
    delegations.called,
    readerValid,
    delegationFound,
    delegations,
  ]);

  useEffect(() => {
    if (config.preContract === undefined) return;
    if (pubKey === undefined) return;
    if (reader.length === 0 || readerValid === false) return;
    if (delegations.data === undefined) return;
    if (delegations.data.pre_delegation.length > 0) return;
    if (
      availableProxyResponse.error !== undefined ||
      availableProxyResponse.data !== undefined
    )
      return;
    if (availableProxyResponse.called === true) return;
    console.log(`no delegation found for ${reader}! Query proxies!!`);
    getAvailableProxies(config.preContract);
    setLoading(true);
  }, [
    config.preContract,
    pubKey,
    reader,
    getAvailableProxies,
    delegations,
    availableProxyResponse,
    readerValid,
  ]);

  useEffect(() => {
    if (contract === undefined) return;
    if (inProgress === true) return;
    if (availableProxyResponse.data === undefined) return;
    if (readerValid === false) return;
    if (pubKey.length === 0 || isBase64(pubKey) === false) return;
    const proxies = availableProxyResponse.data.pre_proxy;
    if (
      contractInfo.data === undefined ||
      proxies.length < contractInfo.data.threshold
    ) {
      toast.error("Not enough proxy available!");
      console.log(
        `ShareData: not enough proxy available, contract=${contractInfo.data}, proxies=${proxies.length}`,
      );
      return;
    }
    (async function () {
      if (contractInfo.data === undefined) return;
      if (availableProxyResponse.data === undefined) return;
      try {
        const r = await createDelegation(
          config.chainId,
          contract,
          pubKey,
          reader,
          contractInfo.data.threshold,
          availableProxyResponse.data.pre_proxy,
        );
        if (r === undefined) {
          delegations.refetch();
          remove(id);
          return;
        }
        setSelectedProxies(r[1]);
        setDelegationCreate(r[0]);
      } catch (error: any) {
        console.log("[Share]: Failed to create delegation: ", error?.message);
        toast.error(`${error.message}`);
        remove(id);
      }
    })();
    subscribeDelegation(config.preContract, pubKey, reader);
    setInProgress(true);
  }, [
    availableProxyResponse,
    contractInfo,
    config,
    contract,
    account,
    pubKey,
    reader,
    inProgress,
    readerValid,
    subscribeDelegation,
    remove,
    contactName,
    delegations,
    id,
  ]);

  useEffect(() => {
    if (
      delegations.data !== undefined &&
      delegations.data?.pre_delegation.length > 0 &&
      loading
    ) {
      setLoading(false);
    }
  }, [loading, setLoading, delegations.data]);

  if (availableProxyResponse.error !== undefined) {
    console.log(
      "ShareData: Failed to get available proxies: ",
      availableProxyResponse.error,
    );
  }

  if (delegations.error !== undefined) {
    console.log("ShareData: Failed to get proxies: ", delegations.error);
  }

  if (contractInfo.error !== undefined) {
    console.log("ShareData: failed to get contract info: ", contractInfo.error);
  }

  const requestedTokens = useMemo(() => {
    if (contractInfo.data === undefined) return 0;
    if (readerValid === false) return 0;
    if (delegationFound === false && delegationCreate === undefined) return 0;
    if (
      delegationCreate === undefined &&
      delegations.data?.pre_delegation !== undefined &&
      delegations.data?.pre_delegation.length > 0 &&
      delegations.data?.pre_delegation[0].delegatee_pubkey !== reader
    )
      return 0;
    if (config.coinDenom !== contractInfo.data.stake_denom) {
      console.log(
        "Something is wrong, contract denom doesn't match frontend denom: ",
        contractInfo.data.stake_denom,
        config.coinDenom,
        reader,
      );
    }
    console.log("Calculate fee for: ", reader);
    let proxies = selectedProxies;
    if (
      delegations.data?.pre_delegation !== undefined &&
      delegations.data?.pre_delegation.length > 0 &&
      delegations.data.pre_delegation[0].proxy_delegations !== undefined
    ) {
      proxies = delegations.data.pre_delegation[0].proxy_delegations.length;
    }
    return contractInfo.data.per_proxy_request_reward_amount * proxies;
  }, [
    contractInfo.data,
    delegationFound,
    delegations.data,
    config.coinDenom,
    reader,
    readerValid,
    delegationCreate,
    selectedProxies,
  ]);

  useEffect(() => {
    if (requestedTokens === 0) return;
    updateCost(id, requestedTokens);
  }, [requestedTokens, id, updateCost]);

  useEffect(() => {
    if (
      ((delegations.data !== undefined &&
        delegations.data?.pre_delegation.length > 0) ||
        delegationCreate !== undefined) &&
      loading
    ) {
      setLoading(false);
    }
  }, [loading, setLoading, delegations.data, delegationCreate]);

  useEffect(() => {
    if (component === undefined) return;
    component.share = async (dataId: string) => {
      if (account === undefined || config.coinDenom === undefined) return;
      if (
        readerValid === false ||
        (delegationFound === false && delegationCreate === undefined) ||
        requestedTokens === 0
      )
        return;
      if (contract === undefined) return;
      try {
        const [msg, coins] = contract.prepareReencryption(
          dataId,
          reader,
          requestedTokens,
          config.coinDenom,
        );
        const r: ShareResponse = {
          reencryptionMsg: msg,
          reencryptionCoins: coins,
          delegationMsg:
            delegationFound === false ? delegationCreate : undefined,
        };
        return r;
      } catch (error) {
        console.log("ShareData error: ", error);
        return;
      }
    };
  }, [
    component,
    contactName,
    reader,
    account,
    contract,
    requestedTokens,
    config.coinDenom,
    readerValid,
    delegationFound,
    delegationCreate,
  ]);

  return (
    <div className="file-upload-share-with-user">
      <div className="file-upload-share-with-user-address">
        <img src={address} />
      </div>
      <div className="file-upload-share-with-user-name">{title}</div>
      <div className="file-upload-share-with-user-trash">
        <img src={trash} onClick={() => remove(id)} />
      </div>
    </div>
  );
};
