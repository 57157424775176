import { ChainInfo } from "@keplr-wallet/types";
import { ApiBackoffConfig, AppConfig } from "./types";
import BigDecimal from "bignumber.js";

export const getWalletConfig = (config: AppConfig): ChainInfo => {
  return {
    chainId: config.chainId,
    chainName: config.chainName,
    rpc: config.rpcAddress,
    rest: config.restAddress,
    bech32Config: {
      bech32PrefixAccAddr: `${config.addressPrefix}`,
      bech32PrefixAccPub: `${config.addressPrefix}pub`,
      bech32PrefixValAddr: `${config.addressPrefix}valoper`,
      bech32PrefixValPub: `${config.addressPrefix}valoperpub`,
      bech32PrefixConsAddr: `${config.addressPrefix}valcons`,
      bech32PrefixConsPub: `${config.addressPrefix}valconspub`,
    },
    currencies: [
      {
        coinDenom: config.coinMap.get(config.feeToken)?.denom!,
        coinMinimalDenom: config.feeToken,
        coinDecimals: config.coinMap.get(config.feeToken)?.decimals!,
      },
    ],
    feeCurrencies: [
      {
        coinDenom: config.coinMap.get(config.feeToken)?.denom!,
        coinMinimalDenom: config.feeToken,
        coinDecimals: config.coinMap.get(config.feeToken)?.decimals!,
      },
    ],
    stakeCurrency: {
      coinDenom: config.coinMap.get(config.feeToken)?.denom!,
      coinMinimalDenom: config.feeToken,
      coinDecimals: config.coinMap.get(config.feeToken)?.decimals!,
      coinGeckoId: config.coinMap.get(config.feeToken)?.coinGeckoId!,
    },
    gasPriceStep: {
      low: config.gasPrice / 2,
      average: config.gasPrice,
      high: config.gasPrice * 2,
    },
    bip44: { coinType: 118 },
    coinType: 118,
  };
};

export const getApiBackoffConfig = (value: AppConfig) => {
  let apiBackoff: ApiBackoffConfig = value.apiBackoff;

  try {
    apiBackoff = {
      ...apiBackoff,
      maxMS: Number.parseInt(process.env.REACT_APP_API_BACKOFF_MAX_MS!, 10),
    };
  } catch (error) {
    console.log(
      "Failed to parse apiBackoff.maxMS: ",
      process.env.REACT_APP_API_BACKOFF_MAX_MS,
    );
  }

  try {
    apiBackoff = {
      ...apiBackoff,
      incrementsMS: Number.parseInt(
        process.env.REACT_APP_API_BACKOFF_INCREMENTS_MS!,
        10,
      ),
    };
  } catch (error) {
    console.log(
      "Failed to parse apiBackoff.incrementMS: ",
      process.env.REACT_APP_API_BACKOFF_INCREMENTS_MS,
    );
  }

  try {
    apiBackoff = {
      ...apiBackoff,
      randomMS: Number.parseInt(
        process.env.REACT_APP_API_BACKOFF_RANDOM_MS!,
        10,
      ),
    };
  } catch (error) {
    console.log(
      "Failed to parse apiBackoff.randomMS: ",
      process.env.REACT_APP_API_BACKOFF_RANDOM_MS,
    );
  }

  return apiBackoff;
};

export const formatSizeUnits = (bytes: number) => {
  let text;
  if (bytes >= 1073741824) {
    text = (bytes / 1073741824).toFixed(2) + " GB";
  } else if (bytes >= 1048576) {
    text = (bytes / 1048576).toFixed(2) + " MB";
  } else if (bytes >= 1024) {
    text = (bytes / 1024).toFixed(2) + " KB";
  } else if (bytes > 1) {
    text = bytes + " bytes";
  } else if (bytes == 1) {
    text = bytes + " byte";
  } else {
    text = "0 bytes";
  }
  return text;
};

export const humanRound = (
  v: BigDecimal,
  precision: number = 100,
): BigDecimal => {
  const exp = v.toExponential();
  const [b, e] = exp.split("e");
  let bb = new BigDecimal(b);
  const ee = new BigDecimal(e);
  const prec = new BigDecimal(precision);
  bb = bb.multipliedBy(prec).integerValue(BigDecimal.ROUND_CEIL).div(prec);
  let m = new BigDecimal(10);
  m = m.pow(ee);
  return bb.multipliedBy(m);
};

export const coinToUICoin = (config: AppConfig, coins: number): number => {
  if (config.uiCoinDenom === "" || config.uiCoinDenom === config.coinDenom)
    return coins;
  let c = new BigDecimal(coins);
  const unit = new BigDecimal(`1e-${config.coin.decimals}`);
  c = c.multipliedBy(unit);
  return humanRound(c).toNumber();
};

export const add = (...args: number[]): number => {
  const r = args
    .map((v) => new BigDecimal(v))
    .reduce((x, y) => x.plus(y), new BigDecimal(0));
  return humanRound(r).toNumber();
};
