import search from "../../assets/search.svg";

import "./AppHeader.scss";
import { Keplr } from "../Keplr";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useCallback } from "react";
import { updateSearchText } from "./searchBarSlice";
import { useMatch } from "react-router-dom";
import { selectAccount } from "../Keplr/walletSlice";
import { Bech32 } from "@cosmjs/encoding";
import getTokensIcon from "../../assets/getTokens.svg";
import user from "../../assets/headerUser.svg";
import { useConfig } from "../../config";
import { toast } from "react-toastify";
import { Button } from "../Button";

export function isValidAddress(input: string, requiredPrefix: string): boolean {
  try {
    const { prefix, data } = Bech32.decode(input);
    if (prefix !== requiredPrefix) {
      return false;
    }
    return data.length === 20;
  } catch {
    return false;
  }
}

const AppHeader = () => {
  const dispatch = useAppDispatch();
  const match = useMatch("/*");
  const account = useAppSelector(selectAccount);
  const config = useConfig();

  const searchBarChange = useCallback(
    (e: any) => {
      e.preventDefault();
      dispatch(updateSearchText(e.target.value));
    },
    [dispatch],
  );

  const getFunds = useCallback(async () => {
    if (account.address === undefined) return;
    if (isValidAddress(account.address, "fetch") === false) {
      toast.error("Invalid address: " + account.address);
      return;
    }
    const prom = fetch(config.faucetURL, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ address: account.address }),
    });
    toast.promise(prom, {
      pending: "Getting test tokens...",
      success:
        "Test tokens requested! It will show up in your wallet after next block!",
      error: "Failed to add test tokens!",
    });
  }, [account, config.faucetURL]);

  return (
    <div className="app-header">
      <div className="app-header-search">
        <img src={search} alt="Search" />
        <input
          placeholder={
            "Search " +
            (match?.pathname === "/contacts" ? "contacts" : "files") +
            "..."
          }
          onChange={searchBarChange}
        />
      </div>
      <div className="app-header-wallet">
        {account !== undefined && account.address.length > 0 && (
          <div className="app-header-wallet-connect">
            <Button
              onClick={getFunds}
              icon={getTokensIcon}
              title="GET TOKENS"
            />
          </div>
        )}
        <div className="app-header-wallet-connect">
          <Keplr />
        </div>
        {account !== undefined && account.address.length > 0 && (
          <div className="app-header-wallet-connect">
            <Button
              disabledHover
              onClick={() => {}}
              icon={user}
              title={account.address.slice(0, 10)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AppHeader;
