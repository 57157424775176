import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { NavBar } from "./components/NavBar";
import { AppHeader } from "./components/AppHeader";
import { Error } from "./views/Error";
import { Home } from "./views/Home";
import { MyFiles } from "./views/MyFiles";
import { SharedFiles } from "./views/SharedFiles";
import { ContractProvider } from "./app/cosmosServices";
import { Contacts } from "./views/Contacts";
import { ToastContainer } from "react-toastify";
import ReactGA from "react-ga4";
import "react-toastify/dist/ReactToastify.css";

export const Router = () => {
  const [openNav, setOpenNav] = useState<boolean>(true);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <BrowserRouter>
      <ContractProvider>
        <div className="routes-container">
          <NavBar open={openNav} setOpen={setOpenNav} />
          <div
            style={{
              width: openNav ? "calc(100% - 250px)" : "calc(100% - 75px)",
            }}>
            <AppHeader />
            <div className="routes-container-pages">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/error" element={<Error />} />
                <Route path="/shared-files" element={<SharedFiles />} />
                <Route path="/files" element={<MyFiles />} />
                <Route path="/contacts" element={<Contacts />} />
              </Routes>
              <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
            </div>
          </div>
        </div>
      </ContractProvider>
    </BrowserRouter>
  );
};
