import axios, { AxiosResponse } from "axios";
import { AccountType } from "../components/Keplr/walletSlice";
import { toBase64 } from "@cosmjs/encoding";
import { serializeSignDoc } from "@cosmjs/launchpad";

declare let window: Window;

interface JWTAuthResponse {
  token: string;
}

interface JWTAuthRequest {
  signature: string;
  public_key: string;
  signed_bytes: string;
}

interface LoginRequestResponse {
  payload: string;
}

interface TokenRequestType {
  address: string;
  public_key: string;
}

class RequestError extends Error {
  constructor(message: string) {
    super(`Request failed: ${message}`);
    this.name = "RequestError";
  }
}

class RejectError extends Error {
  constructor(message: string) {
    super(`Request rejected: ${message}`);
    this.name = "RejectError";
  }
}

const signArbitrary = async (
  chainId: string,
  account: AccountType,
  data: string,
) => {
  const encoder = new TextEncoder();
  const encoded = encoder.encode(data);
  const signDoc = {
    chain_id: "",
    account_number: "0",
    sequence: "0",
    fee: {
      gas: "0",
      amount: [],
    },
    msgs: [
      {
        type: "sign/MsgSignData",
        value: {
          signer: account.address,
          data: toBase64(encoded),
        },
      },
    ],
    memo: "",
  };

  const response = await window.fetchBrowserWallet?.keplr?.signAmino(
    chainId,
    account.address,
    signDoc,
    { isADR36WithString: true } as any,
  );

  if (response === undefined) {
    return undefined;
  }

  return {
    signature: response.signature.signature,
    public_key: account.pubkey,
    signed_bytes: toBase64(serializeSignDoc(response.signed)),
  };
};

export const getJWT = async (
  chainId: string,
  account: AccountType,
  url: string,
) => {
  if (window.fetchBrowserWallet === undefined) {
    console.log("no fetch wallet");
    return "";
  }
  const config = {
    headers: { "Access-Control-Allow-Origin": "*" },
  };
  const request = {
    address: account.address,
    public_key: account.pubkey,
  };
  const r1 = await axios.post<
    LoginRequestResponse,
    AxiosResponse<LoginRequestResponse, TokenRequestType>,
    TokenRequestType
  >(`${url}/request_token`, request, config);
  console.log("Request status: ", r1.status);
  if (r1.status != 200) throw new RequestError(r1.statusText);

  let loginRequest = undefined;
  try {
    loginRequest = await signArbitrary(chainId, account, r1.data.payload);
  } catch (err: any) {
    throw new RejectError(err);
  }

  console.log("Login request: ", loginRequest);

  if (loginRequest === undefined) {
    console.log("Failed to sign challenge!");
    return undefined;
  }

  const r2 = await axios.post<
    JWTAuthResponse,
    AxiosResponse<JWTAuthResponse, JWTAuthRequest>,
    JWTAuthRequest
  >(`${url}/login`, loginRequest, config);

  if (r2.status != 200) throw new RequestError(r1.statusText);
  return r2.data.token;
};
