import { useMemo } from "react";
import { Spinner } from "react-bootstrap";
import { useConfig } from "../../config";
import {
  makeFriendlyPREFragment,
  usePREContractQuery,
  usePREReencryptionFragmentsSubscription,
} from "../../search/pre";
import { PREFragmentUserFriendly } from "../../search/preTypes";
import { DataTable } from "../DataTable";
import { humanFileSize, stringFromBase64 } from "../../utils";
import { DownloadSharedData } from "./DownloadSharedData";
import { useAppSelector } from "../../app/hooks";
import { selectContactsByPubKey } from "../../views/Contacts/contactSlice";
import { selectSearchBarText } from "../AppHeader/searchBarSlice";
import Fuse from "fuse.js";
import Countdown from "react-countdown";

interface SharedDataTableProps {
  pubKey: string;
}

type Fragment = PREFragmentUserFriendly & { expire: number };

export const SharedDataTable = ({ pubKey }: SharedDataTableProps) => {
  const header = [
    { label: "Name", key: "name" },
    { label: "Status", key: "status" },
    { label: "Expire in", key: "expire" },
    { label: "Shared by", key: "sharedBy" },
    { label: "Size", key: "size" },
    { label: "Type", key: "type" },
    { label: "Date shared", key: "dateShared" },
    { label: "Download", key: "download" },
  ];
  const config = useConfig();
  const { data, loading, error } = usePREReencryptionFragmentsSubscription(
    config.preContract,
    pubKey,
  );
  const contractInfo = usePREContractQuery(config.preContract);
  const contacts = useAppSelector(selectContactsByPubKey);

  const searchBarText = useAppSelector(selectSearchBarText);

  if (error !== undefined) {
    console.log("SharedData: failed to get data: ", error);
  }

  if (contractInfo.error !== undefined) {
    console.log(
      "SharedData: failed to get contract info: ",
      contractInfo.error,
    );
  }

  const threshold = contractInfo.data?.threshold ?? 0;

  const fragments = useMemo(() => {
    if (data === undefined || data.length === 0)
      return new Map<string, Fragment[]>();
    const f = new Map<string, Fragment[]>();
    const now = Date.now();
    for (const item of data) {
      const frag = makeFriendlyPREFragment(item);
      const time = new Date(frag.block.timestamp);
      let expire = 0;
      if (config.expireTimeMS > 0) {
        expire = config.expireTimeMS - (now - time.getTime());
      } else if (config.expireTimestamp.length > 0) {
        const parts = config.expireTimestamp.split(":");
        if (parts.length === 3) {
          const date = new Date();
          date.setUTCHours(Number.parseInt(parts[0], 10));
          date.setUTCMinutes(Number.parseInt(parts[1], 10));
          date.setUTCSeconds(Number.parseInt(parts[2], 10));
          const clean = new Date(date);
          clean.setUTCDate(clean.getUTCDate() - 1);
          expire =
            time.getTime() - clean.getTime() > 0
              ? date.getTime() - Date.now()
              : -1;
        }
      }
      if (expire < 0) continue;
      const frag2 = {
        ...frag,
        expire,
      };
      frag2.data?.tags?.set(
        "title",
        stringFromBase64(frag2?.data?.tags?.get("title") ?? ""),
      );
      f.get(frag.data_id)?.push(frag2) ?? f.set(frag.data_id, [frag2]);
    }
    return f;
  }, [data, config.expireTimeMS, config.expireTimestamp]);

  const searchedData = useMemo(() => {
    const frags = Array.from(fragments);
    if (searchBarText === "") return frags;
    const options = {
      includeScore: true,
      keys: [
        {
          name: "title",
          getFn: (row: [string, Fragment[]]) =>
            row[1][0]?.data?.tags?.get("title") ?? "",
        },
        {
          name: "type",
          getFn: (row: [string, Fragment[]]) =>
            row[1][0]?.data?.tags?.get("type") ?? "",
        },
      ],
    };

    const fuse = new Fuse(frags, options);

    const result = fuse.search(searchBarText);

    return result
      .filter((v) => v.score !== undefined && v.score < 0.1)
      .map((v) => v.item);
  }, [fragments, searchBarText]);

  return (
    <div>
      {loading === true && (
        <Spinner
          animation="grow"
          style={{ marginTop: "50px", marginBottom: "50px" }}></Spinner>
      )}
      {loading === false && (
        <DataTable
          selectable={false}
          header={header}
          data={searchedData?.map(([dataId, frags]) => ({
            name: frags[0]?.data?.tags?.get("title") ?? "",
            status:
              frags.length >= threshold
                ? "available"
                : "waiting on reencryption",
            expire: (
              <Countdown
                className="table-data-text"
                date={
                  new Date(
                    Date.now() +
                      frags.reduce((prev, curr) => {
                        return prev.expire < curr.expire ? prev : curr;
                      }).expire,
                  )
                }
              />
            ),
            sharedBy:
              contacts[frags[0].data?.delegator_pubkey ?? ""]?.name ??
              frags[0]?.data?.delegator_pubkey,
            size: humanFileSize(
              Number.parseInt(frags[0]?.data?.tags?.get("size") ?? ""),
            ),
            type: frags[0]?.data?.tags?.get("type") ?? "",
            dateShared: new Date(frags[0].block.timestamp).toLocaleString(),
            download: (
              <DownloadSharedData
                threshold={threshold}
                dataId={dataId}
                fragments={frags}
              />
            ),
          }))}
        />
      )}
    </div>
  );
};
