import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface ContactType {
  name: string;
  pubKey: string;
  notes: string;
}

// object where all keys must be of type ContactType["name"], i.e. strings
interface Addrs {
  [key: ContactType["name"]]: ContactType;
}

interface KeyMap {
  [key: ContactType["pubKey"]]: ContactType;
}

export interface ContactState {
  contacts: Addrs;
  keyMap: KeyMap;
}

const initialState: ContactState = {
  contacts: JSON.parse(localStorage.getItem("contacts") || "{}"),
  keyMap: JSON.parse(localStorage.getItem("contacts_key_map") || "{}"),
};

export const contactsSlice = createSlice({
  name: "contacts",
  initialState,
  reducers: {
    addContact: (state, action: PayloadAction<ContactType>) => {
      state.contacts[action.payload.name] = action.payload;
      state.keyMap[action.payload.pubKey] = action.payload;
    },
    removeContact: (state, action: PayloadAction<ContactType["name"]>) => {
      const pkey = state.contacts[action.payload].pubKey;
      delete state.contacts[action.payload];
      delete state.keyMap[pkey];
    },
    resetContacts: (state) => {
      state.contacts = {};
      state.keyMap = {};
    },
  },
});

export const { addContact, removeContact, resetContacts } =
  contactsSlice.actions;

export const selectContacts = (state: RootState) => state.contacts.contacts;
export const selectContactsByPubKey = (state: RootState) =>
  state.contacts.keyMap;
export const selectContactByPubKey = (pubKey: string) => (state: RootState) =>
  state.contacts.keyMap[pubKey];

export default contactsSlice.reducer;
