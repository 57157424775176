import { PageHeader } from "../../components/PageHeader";
import uploadIcon from "../../assets/upload.svg";

import "./MyFiles.scss";
import { useState } from "react";
import { DataViewer } from "../../components/Data/DataViewer";
import { UploadFile } from "../../components/UploadFile";
import { useAppSelector } from "../../app/hooks";
import { selectAccount } from "../../components/Keplr/walletSlice";

const MyFiles = () => {
  const account = useAppSelector(selectAccount);
  const [showFileUpload, setShowFileUpload] = useState(false);

  return (
    <div className="my-files">
      <div className="my-files-header">
        <PageHeader
          heading="My Files"
          details="Upload or share existing files with your contacts."
          onAction={() => setShowFileUpload(true)}
          actionText="Upload file"
          actionIcon={uploadIcon}
        />
      </div>
      <UploadFile
        show={showFileUpload}
        setShow={setShowFileUpload}
        pubKeyB64={account?.umbralPubKeyB64 ?? ""}
      />
      <DataViewer pubKey={account?.umbralPubKeyB64 ?? ""}></DataViewer>
    </div>
  );
};

export default MyFiles;
