import { Checkbox } from "../Checkbox";

import threedot from "../../assets/threedot.svg";
import "./DataTable.scss";

interface DataTableProps {
  selectable?: boolean;
  allSelected?: boolean;
  selectAllChange?: (value: boolean) => void;
  header: { [key: string]: any }[];
  data: { [key: string]: any }[] | undefined;
  detailsEnabled?: boolean;
}

const DataTable = (props: DataTableProps) => {
  if (props.data === undefined) {
    return null;
  }
  return (
    <div>
      <table className="table">
        <thead className="table-header">
          <tr className="table-row">
            {props.selectable && (
              <th key="select-all" className="table-cell">
                <div className="table-cell-checkbox">
                  <Checkbox value={false} />
                </div>
              </th>
            )}
            {props.header.map((h, index) => (
              <th key={index} className="table-cell">
                {h.label}
              </th>
            ))}
            {props.detailsEnabled === true && (
              <th key="details" className="table-cell-details"></th>
            )}
          </tr>
        </thead>

        <tbody>
          {props.data.map((d, index) => (
            <tr key={index} className="table-row">
              {props.selectable && (
                <td className="table-data table-cell">
                  <div className="table-cell-checkbox">
                    <Checkbox
                      value={d.select ?? false}
                      onChange={(v) => {
                        d.changeSelect !== undefined &&
                          d.changeSelect(d.selectionId, v);
                      }}
                    />
                  </div>
                </td>
              )}
              {props.header.map((h, index) => (
                <td
                  key={index}
                  className={"table-data table-cell cell-" + index}>
                  {typeof d[h.key] === "string" ? (
                    <span className="table-data-text">{d[h.key]}</span>
                  ) : (
                    d[h.key]
                  )}
                </td>
              ))}
              {d["details"] !== undefined && (
                <td
                  key={`${index}_details`}
                  className="table-data cell-details">
                  <img src={threedot} alt="Details" onClick={d["details"]} />
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DataTable;
