import { Spinner } from "react-bootstrap";
import { useConfig } from "../../config";
import {
  useDataSubscription,
  usePREReencryptionRequestSubscription,
} from "../../search/pre";
import { DataTable } from "../../components/DataTable";
import { SharedWith } from "./SharedWith";
import { humanFileSize } from "../../utils";
import { ShareData } from "./ShareData";
import { useAppSelector } from "../../app/hooks";
import { selectAccount } from "../Keplr/walletSlice";
import { useCallback, useMemo, useState } from "react";
import { Checkbox } from "../Checkbox";
import trash from "../../assets/trash.svg";
import { usePREContract } from "../../app/cosmosServices";

import "./DataViewer.scss";
import { selectSearchBarText } from "../AppHeader/searchBarSlice";
import { useFilterData, useSearchedData } from "./utils";
import { DataDetails } from "./DataDetails";
import Countdown from "react-countdown";

interface DataViewerProps {
  pubKey: string;
}

export const DataViewer = ({ pubKey }: DataViewerProps) => {
  const header = [
    { label: "Name", key: "name" },
    { label: "Shared with", key: "sharedWith" },
    { label: "Expire in", key: "expire" },
    { label: "Size", key: "size" },
    { label: "Type", key: "type" },
    { label: "Date uploaded", key: "dateUploaded" },
    { label: "Share file", key: "shareFile" },
  ];
  const config = useConfig();
  const contract = usePREContract();
  const account = useAppSelector(selectAccount);
  const { data, loading, error } = useDataSubscription(
    config.preContract,
    pubKey,
  );
  const reencreq = usePREReencryptionRequestSubscription(
    config.preContract,
    account?.address ?? "",
  );

  const [selectAll, setSelectAll] = useState(false);
  const [selection, setSelection] = useState(new Map<string, boolean>());

  const [openDetails, setOpenDetails] = useState("");

  const searchBarText = useAppSelector(selectSearchBarText);

  if (reencreq.error !== undefined) {
    console.log("[DataViewer]: failed to get reencreq: ", reencreq.error);
  }

  const reencryptionRequests = useMemo(() => {
    if (reencreq.data === undefined) return new Map<string, string[]>();
    const m = new Map<string, string[]>();
    reencreq.data.forEach((v) => {
      m.get(v.data_id)?.push(v.delegatee_pubkey) ??
        m.set(v.data_id, [v.delegatee_pubkey]);
    });
    return m;
  }, [reencreq.data]);

  if (error !== undefined) {
    console.log("DataViewer: error=", error);
  }

  const filteredData = useFilterData(
    data,
    config.expireTimeMS,
    config.expireTimestamp,
    selection,
    setSelection,
  );

  const searchedData = useSearchedData(filteredData, searchBarText);

  const handleSelectAll = (value: boolean) => {
    const sel = new Map<string, boolean>();
    searchedData?.forEach((v) => {
      sel.set(v.data_id, value);
    });
    setSelectAll(value);
    setSelection(sel);
  };

  const removeFiles = useCallback(
    async (e: any) => {
      e.preventDefault();
      const removed = new Set<string>();
      for (const [key, value] of Array.from(selection)) {
        if (value === true) {
          const h = await contract?.removeData(account.address, key);
          console.log(`Data ${key} removal TX sent: ${h}`);
          removed.add(key);
        }
      }
      setSelection((p) => {
        const m = new Map<string, boolean>();
        p.forEach((v, k) => {
          if (removed.has(k)) return;
          m.set(k, v);
        });
        return m;
      });
    },
    [account, contract, selection],
  );

  const selected = useMemo(() => {
    return Array.from(selection.entries()).filter((v) => v[1] === true);
  }, [selection]);

  return (
    <div>
      {loading === true && (
        <Spinner
          animation="grow"
          style={{ marginTop: "50px", marginBottom: "50px" }}></Spinner>
      )}
      {loading === false && (
        <>
          <div className="selectDelete">
            <Checkbox value={selectAll} onChange={handleSelectAll} />
            <div className="selectDeleteLabel">Select All</div>
            {selected.length > 0 && (
              <>
                <div className="selectDeleteTrash">
                  {selected.length} selected
                </div>
                <img src={trash} onClick={removeFiles} alt="Delete files" />
              </>
            )}
            <div id="fileCount">{filteredData?.length || 0} files</div>
          </div>
          <DataTable
            selectable={true}
            header={header}
            detailsEnabled={true}
            data={searchedData?.map((d) => ({
              ...d,
              name: d.tags.get("title") ?? "",
              type: d.tags.get("type") ?? "",
              size: humanFileSize(Number.parseInt(d.tags.get("size") ?? "")),
              dateUploaded: d.timestamp.toLocaleString(),
              expire: (
                <Countdown
                  className="table-data-text"
                  date={new Date(Date.now() + d.expire)}
                />
              ),
              sharedWith: (
                <SharedWith
                  dataId={d.data_id}
                  reencryptionRequests={
                    reencryptionRequests.get(d.data_id) ?? []
                  }
                />
              ),
              shareFile: (
                <div className="my-files-share-file">
                  <ShareData data={d} pubKey={pubKey} />
                </div>
              ),
              details: () => {
                setOpenDetails(d.data_id);
              },
            }))}
          />
          <DataDetails
            open={openDetails}
            onClose={() => {
              setOpenDetails("");
            }}
          />
        </>
      )}
    </div>
  );
};
