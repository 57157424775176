import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface SearchBarState {
  text: string;
}

const initialState: SearchBarState = {
  text: "",
};

export const searchBarSlice = createSlice({
  name: "searchBar",
  initialState,
  reducers: {
    updateSearchText: (state, action: PayloadAction<string>) => {
      state.text = action.payload;
    },

    clearSearchText: (state) => {
      state.text = "";
    },
  },
});

export const { updateSearchText, clearSearchText } = searchBarSlice.actions;

export const selectSearchBarText = (state: RootState) => state.searchBar.text;

export default searchBarSlice.reducer;
