import { fromBase64 } from "@cosmjs/encoding";
import { useConfig } from "../../config";
import { makeFriendlyPREData, useDataLazyQuery } from "../../search/pre";
import { PREFragmentUserFriendly } from "../../search/preTypes";
import { create } from "ipfs-http-client";
import download from "../../assets/download.svg";
import { useEffect, useState } from "react";
import { stringFromBase64 } from "../../utils";
import { toast } from "react-toastify";
import { Button } from "../Button";

interface DownloadSharedDataProps {
  threshold: number;
  dataId: string;
  fragments: PREFragmentUserFriendly[];
}

const downloadURL = (data: any, fileName: string) => {
  const a = document.createElement("a");
  a.href = data;
  a.download = fileName;
  document.body.appendChild(a);
  a.style.display = "none";
  a.click();
  a.remove();
};

export const DownloadSharedData = ({
  threshold,
  dataId,
  fragments,
}: DownloadSharedDataProps) => {
  const config = useConfig();
  const [downloadClicked, setDownloadClicked] = useState(false);
  const [loadData, { data, error, called }] = useDataLazyQuery(
    config.preContract,
    dataId,
  );

  if (error !== undefined) {
    console.log("[DownloadSharedData]: error ", error);
  }

  useEffect(() => {
    const friendlyData = makeFriendlyPREData(data);
    if (called === false) return;
    if (data === undefined) return;
    if (friendlyData === undefined || friendlyData.length !== 1) return;
    if (config === undefined) return;
    if (downloadClicked == false) return;
    setDownloadClicked(false);
    (async function () {
      const client = create({ url: config.ipfsURI });
      let totalSize = 0;
      const idx = [];
      const arrs = [];
      for await (const d of client.cat(dataId)) {
        idx.push(totalSize);
        totalSize += d.byteLength;
        arrs.push(d);
      }
      const dataBytes = new Uint8Array(totalSize);
      for (let i = 0; i < idx.length; ++i) {
        dataBytes.set(arrs[i], idx[i]);
      }
      const keyB64 = friendlyData[0].tags.get("key");
      if (keyB64 === undefined) {
        toast.error("Old decryption not supported. Only AES!");
        return;
      }
      const keyEncrypted = fromBase64(keyB64);

      const senderPubKey = fromBase64(friendlyData[0].delegator_pubkey);
      const capsule = fromBase64(friendlyData[0].capsule);
      const frags = [];
      for (const frag of fragments) {
        frags.push(fromBase64(frag.fragment));
      }
      if (frags.length < threshold) {
        console.log(`Frags ${frags.length}, threshold ${threshold}`);
        toast.error("Not enough fragments to decode!");
        return;
      }
      const aesBucket =
        await window?.fetchBrowserWallet?.umbral.decryptReEncrypted(
          config.chainId,
          senderPubKey,
          capsule,
          frags,
          keyEncrypted,
        );
      if (aesBucket === undefined) {
        console.log("Decrypted key undefined!");
        toast.error("Failed to decode file!");
        return;
      }
      const iv = aesBucket.subarray(0, 12);
      const decryptedKey = aesBucket.subarray(12);
      const key = await window.crypto.subtle.importKey(
        "raw",
        decryptedKey,
        {
          name: "AES-GCM",
          length: 256,
        },
        false,
        ["decrypt"],
      );
      const decrypted = await window.crypto.subtle.decrypt(
        {
          name: "AES-GCM",
          iv,
          tagLength: 128,
        },
        key,
        dataBytes,
      );
      const blob = new Blob([decrypted], {
        type: friendlyData[0].tags.get("type"),
      });
      const url = window.URL.createObjectURL(blob);
      const name = stringFromBase64(friendlyData[0].tags.get("title"));
      downloadURL(url, name.length > 0 ? name : dataId);
      setTimeout(() => window.URL.revokeObjectURL(url), 1000);
    })();
  }, [data, called, config, dataId, fragments, threshold, downloadClicked]);

  const initDecrypt = () => {
    setDownloadClicked(true);
    loadData();
  };

  return (
    <div>
      <Button onClick={() => initDecrypt()} title="Download" icon={download} />
    </div>
  );
};
