import { fromBase64 } from "@cosmjs/encoding";
import { ChangeEvent, useMemo, useState } from "react";
import { Form, Offcanvas } from "react-bootstrap";
import { useAppSelector } from "../../app/hooks";
import { isBase64 } from "../../utils";
import { selectContacts } from "../../views/Contacts/contactSlice";
import { Button } from "../Button";

import "./ContactForm.scss";

interface ContactFormProps {
  addContact: Function;
  show: boolean;
  setShow: (show: boolean) => void;
}

export const ContactForm = (props: ContactFormProps) => {
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [notes, setNotes] = useState("");
  const contacts = useAppSelector(selectContacts);

  const contactNames = useMemo(() => {
    const m = new Set<string>();
    for (const contact of Object.values(contacts)) {
      m.add(contact.name);
    }
    return m;
  }, [contacts]);

  const contactKeys = useMemo(() => {
    const m = new Set<string>();
    for (const contact of Object.values(contacts)) {
      m.add(contact.pubKey);
    }
    return m;
  }, [contacts]);

  const nameValid = name.length > 0 && contactNames.has(name) === false;
  const addressValid =
    address.length > 0 &&
    isBase64(address) &&
    contactKeys.has(address) === false &&
    fromBase64(address).length === 33;

  const handleSubmit = (event: any) => {
    event.preventDefault();
    props.addContact(name, address, notes);
    props.setShow(false);
    setName("");
    setAddress("");
    setNotes("");
  };

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleAddressChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAddress(event.target.value);
  };

  const handleNotesChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNotes(event.target.value);
  };

  return (
    <Offcanvas
      show={props.show}
      onHide={() => props.setShow(false)}
      id="add-contact"
      size="lg"
      placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Create contact</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <p>
          Add a contact by submitting a name and public key. <br /> Please note
          that these are only stored in local storage in your browser. This
          means that they will not show up in a different browser, and will be
          deleted if you clear your browser storage.
        </p>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Name *</Form.Label>
            <Form.Control
              type="text"
              value={name}
              onChange={handleNameChange}
              isValid={nameValid}
              isInvalid={nameValid === false}
            />
            <Form.Control.Feedback type="invalid">
              Please enter a valid name! This needs to be unique!
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Public Key *</Form.Label>
            <Form.Control
              type="text"
              value={address}
              onChange={handleAddressChange}
              isValid={addressValid}
              isInvalid={addressValid === false}
            />
            <Form.Control.Feedback type="invalid">
              Please enter a valid public key. This can not have spaces in it,
              and has to be new!
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Notes</Form.Label>
            <Form.Control
              type="text"
              value={notes}
              onChange={handleNotesChange}
            />
          </Form.Group>
          <Button
            title="Add"
            onClick={() => {}}
            disabled={nameValid === false || addressValid === false}
          />
        </Form>
      </Offcanvas.Body>
    </Offcanvas>
  );
};
