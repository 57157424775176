import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface AccountType {
  address: string;
  pubkey: string;
  pubkeyB64: string;
  umbralPubKeyB64: string;
}

export interface WalletState {
  accounts: AccountType[];
}

const initialState: WalletState = {
  accounts: [],
};

export const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    setAccounts: (state, action: PayloadAction<AccountType[]>) => {
      state.accounts = action.payload;
    },
  },
});

export const { setAccounts } = walletSlice.actions;

export default walletSlice.reducer;

export const selectAccounts = (state: RootState) => state.wallet.accounts;
export const selectAccount = (state: RootState) => state.wallet.accounts[0];
